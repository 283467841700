import { formatDate } from '@/shared/lib/formatting/dates';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import { formatRelativeDate } from '@/shared/lib/relativeDate';
import { OutputFormatter } from '@/stories/ValueFormatters/OutputFormatter';
import { getMergedClasses } from '@/stories/ValueFormatters/utils';

export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export type DateFormat =
  | 'YYYY'
  | 'MM'
  | 'MMM'
  | 'MM/DD/YYYY'
  | 'MMMM D, YYYY'
  | 'MMM D, YYYY'
  | 'Relative';

export const DateFormatter = ({
  value,
  dateFormat = DEFAULT_DATE_FORMAT,
  ...props
}: {
  value: Date;
  dateFormat?: DateFormat;
  classes?: {
    allParts?: string;
  };
}) => {
  if (value == null) return DEFAULT_NUMBER_FALLBACK;

  let formattedDate = '';

  if (dateFormat === 'Relative') {
    formattedDate = formatRelativeDate(value, new Date());
  } else {
    formattedDate = formatDate(value, dateFormat);
  }
  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
  });

  return (
    <>
      <OutputFormatter
        classes={{
          ...classes,
        }}
      >
        {formattedDate}
      </OutputFormatter>
    </>
  );
};
