import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { Button, Popover, SearchInput } from '@/stories';
import {
  buildObjectId,
  GROUPING_TYPE_TO_OBJECT_TYPE,
  idToObjectMapper,
  isAssetId,
  isSegmentId,
  parseObjectId,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib';
import { capitalize, intersection } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import CheckList, { ListOption } from 'stories/Checkbox/CheckList';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { EagleEyeDashboardWidgetContext } from '../../../widgetsHelpers';
import { XYChartSingleKpiWidgetState } from '../widget';

interface Props {
  context: EagleEyeDashboardWidgetContext;
  state: XYChartSingleKpiWidgetState;
  onStateChange: (state: XYChartSingleKpiWidgetState) => void;
}

export function MixedDropdown({ context, state, onStateChange }: Props) {
  const { assets: assetsContext, segments: segmentsContext } = context;
  const contextObjects = [
    ...assetsContext.map((asset) => ({
      ...asset,
      type: 'asset',
      name: asset.name,
    })),
    ...segmentsContext.map((segment) => ({
      ...segment,
      type: 'segment',
      name: segment.title,
    })),
  ];
  const displayedObjects = contextObjects.filter((obj) => {
    switch (state.groupingType) {
      case 'assets': {
        return obj.type === GROUPING_TYPE_TO_OBJECT_TYPE.assets;
      }
      case 'segments': {
        return obj.type === GROUPING_TYPE_TO_OBJECT_TYPE.segments;
      }
      case 'mixed':
      default: {
        return true;
      }
    }
  });

  const stateObjects = [
    ...state.assets.map(idToObjectMapper('asset')),
    ...state.segments.map(idToObjectMapper('segment')),
  ];
  const { inputProps, filteredItems } = useItemsFilterByText(
    displayedObjects,
    'name',
  );

  const handleChange = (newObjects: ListOption<string>[]) => {
    onStateChange({
      ...state,
      assets: newObjects
        .filter((obj) => isAssetId(obj.value))
        .map((obj) => parseObjectId(obj.value).id),
      segments: newObjects
        .filter((obj) => isSegmentId(obj.value))
        .map((obj) => parseObjectId(obj.value).id),
    });
  };

  const counter = useMemo(() => {
    const assetsLen = intersection(
      state.assets,
      assetsContext.map((a) => a.id),
    ).length;
    const segmentsLen = intersection(
      state.segments,
      segmentsContext.map((s) => s.id),
    ).length;

    switch (state.groupingType) {
      case 'assets': {
        return assetsLen;
      }
      case 'segments': {
        return segmentsLen;
      }
      case 'mixed':
      default: {
        return assetsLen + segmentsLen;
      }
    }
  }, [state.groupingType, state.assets, state.segments, context]);

  return (
    <Popover
      className="flex max-h-[280px] flex-col gap-tw-2 px-0 py-tw-2"
      offset={DEFAULT_DROPDOWN_OFFSET}
      hiddenArrow
      trigger="click"
      placement={'bottom-start'}
      maxWidth={300}
      template={
        <>
          <SearchInput
            {...inputProps}
            className="mx-tw-2"
            placeholder="Search objects"
          />
          <CheckList
            className="overflow-y-auto px-tw-4 pt-tw-2"
            selectAll
            items={filteredItems.map((item) => ({
              label: item.name,
              value: buildObjectId(item),
            }))}
            onChange={(items) => handleChange(items)}
            value={stateObjects.map((obj) => ({
              label: obj.id,
              value: buildObjectId(obj),
            }))}
          />
        </>
      }
    >
      <Button size="s" iconName="asset" variant="secondary">
        {pluralize(
          state.groupingType === 'mixed'
            ? 'Objects'
            : capitalize(state.groupingType),
          counter,
          true,
        )}
      </Button>
    </Popover>
  );
}
