import { isDistribution } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useEditManualEntry';
import {
  Contribution,
  Distribution,
  InvestmentClassLabel,
} from '@/entities/return';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CapitalEntry } from 'bundles/REturn/actions/types';
import UnitQuantity from 'bundles/REturn/components/Home/formatters/UnitQuantity';
import { IColumn, IRowBase } from 'bundles/Shared/components/Table/types';
import {
  formatDate,
  formatDateToQuarterWithPrefix,
} from '@/shared/lib/formatting/dates';
import { useMemo } from 'react';
import { Button, CurrencyFormatter, IconButton } from 'stories/index';
import { INITIAL_LABEL_JSX } from '../../../../manageCapitalInvestmentModal/Columns/dumbJSX';
import { distributionKindFormatter } from '@/entities/return/ui/formatters';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';

export function getCapitalEntryKindColumn<
  R extends IRowBase & Pick<Distribution, 'kind' | 'splitItems'>,
>(type: TransactionSource['kind']): IColumn<R> {
  return {
    dataField: 'kind',
    headerAlign: type === 'distribution' ? 'end' : 'start',
    text: 'Kind',
    hidden: type !== 'distribution',
    headerClasses: 'w-[148px]',
    formatter: distributionKindFormatter,
  };
}

export function getCapitalEntryAmountColumn<
  R extends IRowBase & {
    amountCents: number;
    units?: number;
    unitsQuantity?: number;
  },
>({
  hasUnits,
  headerAlign,
}: {
  hasUnits?: boolean;
} & Pick<IColumn<R>, 'headerAlign'>): IColumn<R> {
  return {
    dataField: 'amountCents',
    headerAlign,
    text: 'Amount',
    classes: 'text-right',
    formatter: ({ row }) => {
      const textColorClass =
        row._type == 'distribution' || row.amountCents >= 0
          ? 'text-dark-60'
          : 'text-danger-070';
      return (
        <>
          {row.amount != 0 && (
            <CurrencyFormatter
              // TODO: FE-2520 - use amountCents
              value={row.amount ?? convertCentsToDollars(row.amountCents)}
              classes={{ allParts: cn('secondary-regular', textColorClass) }}
            />
          )}
          {hasUnits && (
            <div>
              <UnitQuantity units={row.units} />
            </div>
          )}
        </>
      );
    },
  };
}

export function getCapitalEntryClass(
  type: TransactionSource['kind'],
): IColumn<Contribution> {
  return {
    dataField: 'investmentClass',
    headerAlign: 'center',
    text: 'Class',
    hidden: type === 'distribution',
    headerClasses: 'w-[70px]',
    classes: 'text-center',
    formatter: ({ row }) => (
      <InvestmentClassLabel investmentClass={row.investmentClass} />
    ),
  };
}

export const capitalEntryDateColumn: IColumn<CapitalEntry> = {
  dataField: 'date',
  text: 'Date',
  formatter: ({ row }) => (
    <div className="flex flex-col gap-tw-0.5">
      <span className="secondary-regular text-dark-60">
        {formatDate(row.date as DateString, 'MM-DD-YYYY')}
      </span>
      {isDistribution(row) && row.period && row.periodType === 'quarterly' && (
        <span className="secondary-regular text-neutral-500">
          {formatDateToQuarterWithPrefix(row.period as DateString)}
        </span>
      )}
      {isDistribution(row) && row.period && row.periodType === 'monthly' && (
        <span className="secondary-regular text-neutral-500">
          {formatDate(row.period as DateString, 'MMM-YYYY')}
        </span>
      )}
      {row.transferDate && (
        <span className="secondary-regular mt-xs text-neutral-500">
          <div>Transfer Date:</div>
          {formatDate(row.transferDate, 'MM-DD-YYYY')}
        </span>
      )}
    </div>
  ),
};

export function useEntryColumns({
  entries,
  type,
}: {
  entries: CapitalEntry[];
  type: TransactionSource['kind'];
}) {
  const columns = useMemo<IColumn<CapitalEntry>[]>(
    () => [
      {
        dataField: 'investment_entity',
        text: 'Investment Entity',
        formatter: ({ row }) => (
          <span className="inline-regular text-light-90">
            {row.investmentEntity.name}
          </span>
        ),
      },
      capitalEntryDateColumn,
      getCapitalEntryAmountColumn({
        hasUnits: type === 'contribution',
        headerAlign: type === 'contribution' ? 'end' : 'start',
      }),
      getCapitalEntryKindColumn<CapitalEntry>(type),
    ],
    [entries, type],
  );
  return columns;
}

export function useEntryColumnsForSplitting({
  deps,
  type,
  initialEntryId,
  actions,
  selectedEntryIds,
}: {
  type: TransactionSource['kind'];
  initialEntryId: CapitalEntry['id'];
  deps: unknown[];
  actions: {
    onAddEntry: (e: CapitalEntry) => void;
    onRemoveEntry: (id: CapitalEntry['id']) => void;
  };
  selectedEntryIds: CapitalEntry['id'][];
}) {
  const columns = useMemo<IColumn<CapitalEntry>[]>(
    () => [
      {
        dataField: 'investment_entity',
        text: 'Investment Entity',
        headerStyle: {
          width: 250,
        },
        formatter: ({ row }) => {
          const isInitial = row.id === initialEntryId;
          const isSelected = selectedEntryIds.includes(row.id);

          const action = isSelected ? (
            <IconButton
              iconName="closeSmall"
              className="bg-transparent"
              onClick={() => actions.onRemoveEntry(row.id)}
            />
          ) : (
            <Button
              variant="secondary"
              size="xs"
              onClick={() => actions.onAddEntry(row)}
            >
              Add
            </Button>
          );

          return (
            <div className="flex items-center justify-between">
              <span className="inline-regular text-light-90">
                {row.investmentEntity.name}
              </span>
              {isInitial && INITIAL_LABEL_JSX}

              {!isInitial && action}
            </div>
          );
        },
      },
      capitalEntryDateColumn,
      getCapitalEntryAmountColumn({
        hasUnits: type === 'contribution',
        headerAlign: type === 'contribution' ? 'end' : 'start',
      }),
      getCapitalEntryKindColumn<CapitalEntry>(type),
    ],
    deps,
  );
  return columns;
}
