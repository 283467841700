import { GroupingTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { Field } from '@/stories';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  PeriodShiftField,
  UpdateWidgetTitle,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { HideTotalField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import {
  DefaultColumnsTab,
  toggleAllColumnsHidden,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableSingleDateBulkGroupConfig } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group';
import { KpiTableSingleDateWidgetConfigColumnPanel } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/column';
import {
  KpiTableWidgetSingleDateConfigForm,
  transformKpiTableSingleDateWidgetConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/component.form';
import {
  moveColumnAndSyncGroup,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
] as const satisfies readonly IThinTabItem[];

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    >();
  const methods = useForm<KpiTableWidgetSingleDateConfigForm>({
    defaultValues: {
      ...transformKpiTableSingleDateWidgetConfigToForm(widget.widgetConfig),
    },
  });

  const { control } = methods;

  useUpdateWidgetConfigEffect<
    KpiTableWidgetSingleDateConfigForm,
    typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
  >(methods, updateDefaultOptions);

  return (
    <FieldsContainer>
      <UpdateWidgetTitle />
      <Field labelText="Period Shift">
        <PeriodShiftField control={control} name="period_shift" />
      </Field>
      <HideTotalField fieldType="row" control={control} name="hide_total_row" />
      <GroupingTypeField
        availableGroupingTypes={['assets', 'segments']}
        control={control}
        name="groupingType"
      />
    </FieldsContainer>
  );
};

export function KpiTableSingleDateWidgetConfigComponent() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            BulkGroupModal={KpiTableSingleDateBulkGroupConfig}
            ColumnModal={KpiTableSingleDateWidgetConfigColumnPanel}
            widgetType={EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE}
            actionsOverrides={{
              moveColumn: moveColumnAndSyncGroup,
              toggleAllColumnsHidden,
            }}
          />
        )}
      </div>
    </div>
  );
}
