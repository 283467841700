import { PERIOD_SHIFT_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { GroupingTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Dropdown, DropdownItem, Field, Icon, IconButton } from '@/stories';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import styles from 'bundles/Shared/components/layouts/dashboard/WidgetLayout.module.scss';
import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  OBJECT_DASHBOARD_SECTION_TYPE,
  UNGROUPED_GROUP_KEY,
} from 'bundles/Shared/entities/dashboard';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import {
  DEFAULT_GROUP_TEXT_CLASS,
  isHideColGroupDefEnabled,
  PeriodShiftField,
  UpdateWidgetTitle,
  useUpdateWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { HideTotalField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import { PeriodTypesField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodTypesField';
import {
  buildDraggableId,
  cloneColumn,
  DATE_PICKER_SETTINGS_OPTIONS,
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH,
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK,
  GROUPS_DROPPABLE_ID,
  KpiTableWidgetConfigColumn,
  KpiTableWidgetConfigColumnGroup,
  KpiTableWidgetConfigColumnPanel,
  KpiTableWidgetConfigForm,
  moveColumn,
  moveGroup,
  removeColumn,
  removeGroup,
  SUPPORTED_KPI_TABLE_PERIOD_TYPES_OPTIONS,
  toggleAllColumnsHidden,
  toggleColumnHidden,
  toggleGroupHidden,
  transformKpiTableWidgetConfigToForm,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableSingleDateWidgetDataColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { orderBy } from 'lodash-es';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Button from 'stories/Button/Button';
import { FieldsContainer } from 'stories/Field/Field';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
] as const satisfies readonly IThinTabItem[];

const MonthFields = () => {
  const { control } = useFormContext<KpiTableWidgetConfigForm>();

  return (
    <>
      <PeriodTypesField
        control={control}
        options={SUPPORTED_KPI_TABLE_PERIOD_TYPES_OPTIONS}
      />
    </>
  );
};

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE>();
  const methods = useForm<KpiTableWidgetConfigForm>({
    resolver: yupResolver(PERIOD_SHIFT_SCHEMA),
    defaultValues: {
      ...(widget.widgetConfig.default_options.period_type !== 'week'
        ? DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH
        : DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK),
      ...transformKpiTableWidgetConfigToForm(widget.widgetConfig),
    },
  });
  const { control, watch, reset } = methods;

  useUpdateWidgetConfigEffect<
    KpiTableWidgetConfigForm,
    typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
  >(methods, updateDefaultOptions);

  const date_picker_settings = watch('datePickerSetting');

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Controller
          render={({ field }) => (
            <RadioGroup
              options={DATE_PICKER_SETTINGS_OPTIONS}
              value={field.value}
              onChange={(o) => {
                field.onChange(o.value);
                reset(
                  o.value === 'month'
                    ? DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH
                    : DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK,
                );
              }}
            />
          )}
          name="datePickerSetting"
          control={control}
        />
        {date_picker_settings === 'month' && <MonthFields />}
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <HideTotalField fieldType="row" control={control} name="hideTotalRow" />
        <GroupingTypeField
          availableGroupingTypes={['assets', 'segments']}
          control={control}
          name="groupingType"
        />
      </FieldsContainer>
    </FormProvider>
  );
};

export const DefaultColumnsTab = <
  WidgetType extends
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
>({
  widgetType,
  ColumnModal,
  disableGroupAdding,
  BulkGroupModal,
  actionsOverrides,
}: {
  widgetType:
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE;
  ColumnModal: React.FC<
    DialogProps<{
      createNew: boolean;
    }> & {
      columnConfig?:
        | KpiTableWidgetConfigColumn
        | KpiTableSingleDateWidgetDataColumn;
    }
  >;
  BulkGroupModal?: React.FC<DialogProps & { groupId: string }>;
  // todo think about action-reducer pattern
  actionsOverrides?: {
    moveColumn?: (
      ...args: Parameters<typeof moveColumn>
    ) => ReturnType<typeof moveColumn>;
    toggleAllColumnsHidden?: typeof toggleAllColumnsHidden;
  };
  disableGroupAdding?: boolean;
}) => {
  const [columnBulkGroupModalState, setColumnBulkGroupModalState] = useState<{
    groupId: string;
  } | null>();
  const [columnGroupModalState, setColumnGroupModalState] = useState<Omit<
    React.ComponentProps<typeof KpiTableWidgetConfigColumnGroup>,
    'onClose'
  > | null>(null);
  const [columnModalState, setColumnModalState] = useState<Omit<
    React.ComponentProps<typeof KpiTableWidgetConfigColumnPanel>,
    'onClose'
  > | null>(null);
  const { widget } = useWidgetConfig<WidgetType>();
  const { columns = [], viz_config } = widget.widgetConfig;
  const [updateConfig] = useUpdateWidgetConfig(widgetType);

  const groups = orderBy(viz_config.column_groups ?? [], 'order');

  const handleSortEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!destination) return;
    if (source.droppableId === GROUPS_DROPPABLE_ID) {
      updateConfig({
        config: moveGroup(
          {
            fromIndex: source.index,
            toIndex: destination.index,
          },
          widget.widgetConfig,
        ),
      });
      return;
    }
    const moveColumnAction = actionsOverrides?.moveColumn ?? moveColumn;
    updateConfig({
      config: moveColumnAction(
        {
          fromIndex: source.index,
          toIndex: destination.index,
          sourceGroupId: source.droppableId,
          targetGroupId: destination.droppableId,
        },
        widget.widgetConfig,
      ),
    });
  };

  const renderGroup = (groupId: string, index?: number) => {
    const colGroup = viz_config.column_groups?.find(
      (g) => g.group_id === groupId,
    );
    const isUngrouped = groupId === UNGROUPED_GROUP_KEY || colGroup == null;
    const colGroupColumns = orderBy(
      (viz_config.columns ?? []).filter(
        (c) => c.group_id == (isUngrouped ? null : groupId),
      ),
      'order',
    );
    const color = colGroup?.background;
    const handleRemove = () => {
      updateConfig({
        config: removeGroup({ groupId }, widget.widgetConfig),
      });
    };
    const handleEdit = () => {
      setColumnGroupModalState({
        type: 'edit',
        group: colGroup,
        widgetType,
      });
    };

    const hideFeatureEnabled = isHideColGroupDefEnabled(groupId);

    const renderGroupContent = (provided?: DraggableProvided) => {
      return (
        <WidgetSettingsListLayout.SortableList droppableId={groupId}>
          <WidgetSettingsListLayout.ListItem
            checked={hideFeatureEnabled ? !colGroup?.hidden : null}
            onChange={
              hideFeatureEnabled
                ? () => {
                    updateConfig({
                      config: toggleGroupHidden(
                        {
                          groupId,
                        },
                        widget.widgetConfig,
                      ),
                    });
                  }
                : null
            }
          >
            {provided && (
              <Icon
                className={styles.sortableItem__dragHandle}
                iconName="move"
                {...provided.dragHandleProps}
              />
            )}
            <Icon
              style={{
                color,
              }}
              iconName={colGroup?.icon ?? 'groupByFilled'}
            />
            <span
              className={cn(
                'inline-semibold overflow-hidden text-ellipsis whitespace-nowrap text-nowrap',
                DEFAULT_GROUP_TEXT_CLASS,
              )}
              style={{
                color,
              }}
            >
              {isUngrouped ? 'Ungrouped Columns' : colGroup.header_name}
            </span>
            <div className="grow" />
            <WidgetSettingsListLayout.ListItem.ActionsPanel
              className={cn(isUngrouped && 'hidden')}
            >
              {BulkGroupModal && !isUngrouped && (
                <IconButton
                  disabled={colGroupColumns.length === 0}
                  onClick={() =>
                    setColumnBulkGroupModalState({
                      groupId: colGroup.group_id,
                    })
                  }
                  iconName="configurations"
                />
              )}
              <Dropdown
                className={'sre-dropdown-v2'}
                items={
                  <>
                    <DropdownItem iconName="edit" onClick={handleEdit}>
                      Edit
                    </DropdownItem>
                    <DropdownItem iconName="trash" onClick={handleRemove}>
                      Remove
                    </DropdownItem>
                  </>
                }
              >
                <IconButton iconName="more" />
              </Dropdown>
              <IconButton
                onClick={() => {
                  setColumnModalState({
                    type: 'create',
                    groupId,
                  });
                }}
                iconName="addSmall"
              />
            </WidgetSettingsListLayout.ListItem.ActionsPanel>
          </WidgetSettingsListLayout.ListItem>
          {colGroupColumns.map((columnSettings, colIndex) => {
            const column = columns.find(
              (c) => c.key.toString() === columnSettings.key,
            )!;

            if (column == null) return null; // @Nikita handle this case, when we remove column it crashes without this check

            const handleRemoveColumn = () => {
              updateConfig({
                config: removeColumn(
                  {
                    columnId: column.key.toString(),
                  },
                  widget.widgetConfig,
                ),
              });
            };
            return (
              <WidgetSettingsListLayout.SortableListItem
                draggableId={buildDraggableId(columnSettings.col_id, 'column')}
                index={colIndex}
                listIndex={colIndex}
                key={column.key}
                checked={!columnSettings.hidden}
                onChange={() => {
                  updateConfig({
                    config: toggleColumnHidden(
                      {
                        columnId: column.key.toString(),
                      },
                      widget.widgetConfig,
                    ),
                  });
                }}
              >
                <WidgetSettingsListLayout.ListItem.Text>
                  {column.label}
                </WidgetSettingsListLayout.ListItem.Text>
                <div className="grow" />
                <WidgetSettingsListLayout.ListItem.ActionsPanel>
                  <IconButton
                    iconName="copy"
                    onClick={() => {
                      updateConfig({
                        config: cloneColumn(
                          {
                            columnId: column.key,
                            groupId: isUngrouped ? undefined : groupId,
                          },
                          widget.widgetConfig,
                        ),
                      });
                    }}
                  />
                  {column.expression &&
                    isFormulaByExpression(column.expression) && (
                      <InspectFormulaButton reference={column.expression} />
                    )}
                  <IconButton
                    onClick={() => {
                      setColumnModalState({
                        type: 'edit',
                        columnSettings,
                        columnConfig: column,
                        groupId,
                      });
                    }}
                    iconName="edit"
                  />
                  <IconButton onClick={handleRemoveColumn} iconName="trash" />
                </WidgetSettingsListLayout.ListItem.ActionsPanel>
              </WidgetSettingsListLayout.SortableListItem>
            );
          })}
        </WidgetSettingsListLayout.SortableList>
      );
    };
    return isUngrouped ? (
      renderGroupContent()
    ) : (
      <Draggable
        key={colGroup.group_id}
        draggableId={buildDraggableId(colGroup.group_id.toString(), 'group')}
        index={index!}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            {renderGroupContent(provided)}
          </div>
        )}
      </Draggable>
    );
  };

  const allColumnsShown = !viz_config?.columns?.some((c) => c.hidden);

  return (
    <>
      <WidgetSettingsListLayout>
        <WidgetSettingsListLayout.Header
          checked={
            actionsOverrides?.toggleAllColumnsHidden != null
              ? allColumnsShown
              : undefined
          }
          onChange={
            actionsOverrides?.toggleAllColumnsHidden != null
              ? (e) => {
                  const config = actionsOverrides?.toggleAllColumnsHidden?.(
                    e.target.checked,
                    widget.widgetConfig,
                  );
                  updateConfig({ config });
                }
              : undefined
          }
          label={
            columns.length === 0
              ? 'No Columns Yet'
              : `${columns.length} ${pluralize('COLUMN', columns.length)}`
          }
        >
          <Dropdown
            className="sre-dropdown-v2"
            items={
              <>
                {!disableGroupAdding && (
                  <DropdownItem
                    onClick={() => {
                      setColumnGroupModalState({
                        type: 'create',
                        widgetType,
                      });
                    }}
                  >
                    Group
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => {
                    setColumnModalState({
                      type: 'create',
                    });
                  }}
                >
                  Column
                </DropdownItem>
              </>
            }
          >
            <Button
              size="xs"
              variant="secondary"
              iconPosition="right"
              iconName="bottom"
            >
              Add
            </Button>
          </Dropdown>
        </WidgetSettingsListLayout.Header>
        <DragDropContext onDragEnd={handleSortEnd}>
          {renderGroup(UNGROUPED_GROUP_KEY)}
          <Droppable type="groups" droppableId={GROUPS_DROPPABLE_ID}>
            {(provided) => (
              <div
                className="flex flex-col gap-tw-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {groups.map((group, index) =>
                  renderGroup(group.group_id, index),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </WidgetSettingsListLayout>
      {columnModalState != null && (
        <ColumnModal
          {...columnModalState}
          onClose={() => setColumnModalState(null)}
          onSubmit={(res) => {
            setColumnModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setColumnModalState({
                  type: 'create',
                });
              });
            }
          }}
        />
      )}
      {columnBulkGroupModalState != null && BulkGroupModal != null && (
        <BulkGroupModal
          {...columnBulkGroupModalState}
          onClose={() => setColumnBulkGroupModalState(null)}
        />
      )}
      {columnGroupModalState != null && (
        <KpiTableWidgetConfigColumnGroup
          {...columnGroupModalState}
          onClose={() => setColumnGroupModalState(null)}
        />
      )}
    </>
  );
};

export function KpiTableWidgetConfigComponent() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={KpiTableWidgetConfigColumnPanel}
            widgetType={EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE}
            actionsOverrides={{
              toggleAllColumnsHidden,
            }}
          />
        )}
      </div>
    </div>
  );
}
