export class DateSSN {
  private static ZERO_DATE = new Date(1899, 11, 30);
  #value: number;

  #millisecondsInSecond = 1000 as const;
  #secondsInMinute = 60 as const;
  #minutesInHour = 60 as const;
  #hoursInDay = 24 as const;

  #days =
    this.#millisecondsInSecond *
    this.#secondsInMinute *
    this.#minutesInHour *
    this.#hoursInDay;

  constructor(value: number | Date) {
    if (typeof value === 'number') {
      this.#value = Math.round(value);
    } else if (value instanceof Date) {
      this.#value = Math.round(
        (value.getTime() - DateSSN.ZERO_DATE.getTime()) / this.#days,
      );
    } else {
      throw new Error('Invalid value: ', value);
    }
  }
  public toNumber(): number {
    return this.#value;
  }

  public toDate(): Date {
    const result = new Date(
      DateSSN.ZERO_DATE.getTime() + this.#value * this.#days,
    );
    return result;
  }
}
