import {
  DEFAULT_EAGLE_EYE_AMCHART_CONFIG_TEMPLATE,
  EAGLE_EYE_CATEGORICAL_AMCHART_CONFIG_TEMPLATE,
} from './amchartDefaults';
import { XY_CHART_WIDGET_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/xyChart';
import { EagleEyeDashboardXYChartWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/component';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { XyChartSingleKpiWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { EagleEyeXYChartWidget } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';

export const DEFAULT_EAGLE_EYE_XY_CHART_DATE_CONFIG = {
  kpis: [],
  granularities: ['day', 'week', 'month'],
  default_options: {
    granularity: 'month',
    date_from: {
      period_type: 'month',
      period_shift: {
        months: 12,
      },
    },
    grouping_type: 'assets',
  },
  am_chart_config: DEFAULT_EAGLE_EYE_AMCHART_CONFIG_TEMPLATE,
} satisfies XyChartSingleKpiWidgetConfigDto;

export const EAGLE_EYE_XY_CHART_CATEGORICAL_CONFIG = {
  kpis: [],
  default_options: {
    granularities: ['month'],
    granularity: 'month',
  },
  am_chart_config: EAGLE_EYE_CATEGORICAL_AMCHART_CONFIG_TEMPLATE,
} as const satisfies XyChartSingleKpiWidgetConfigDto;

export const XY_SINGLE_KPI_CHART_WIDGET_CONFIG = {
  ...XY_CHART_WIDGET_CONFIG,
  title: 'XY Chart Single KPI',
  Component: EagleEyeXYChartWidget,
  ConfigComponent: EagleEyeDashboardXYChartWidgetConfig,
} as const satisfies WidgetConfiguration;
