import { ReportDashboardSectionPosition } from '@/bundles/Shared/entities/dashboard';
import {
  ColumSizeWidgetState,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  isWidgetSectionPositionWidthFullSize,
  PeriodTypeWidgetState,
  WidgetStateColumns,
  WidgetStatePeriod,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import {
  showLegalEntitiesDropdownByWidgetContext,
  useFinancialTableWidgetAutoColumDef,
  useFinancialTableWidgetColDef,
  useFinancialTableWidgetDefaultColDef,
  useFinancialTableWidgetRowData,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { useFinancialTableWidgetExcelStyles } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib/useFinancialTableWidgetExcelStyles';
import { useFinancialTableWidgetsRowsDisplayOptionsExcelStyles } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib/useFinancialTableWidgetsRowsDisplayOptionsExcelStyles';
import {
  useWidgetFullScreen,
  WidgetStateFullScreenIconButton,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { WidgetStateLegalEntities } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateLegalEntities';
import { WidgetStateReckonerPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStatePeriodType';
import { WidgetTable } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { useFinancialWidgetColumnsVisibilityState } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useColumnsVisibilityState';
import { useWidgetTableColumnSize } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useWidgetTableColumnSize';
import { FinancialTableSingeDateWidgetNS } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { LegalEntity } from '@/entities/core/legalEntity';
import { useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { addOrRemoveItemInList } from '@/shared/lib/listHelpers/addOrRemoveItemInList';
import { IAsset } from '@/types/Asset';
import { AgGridReact } from 'ag-grid-react';
import {
  getDefaultTableExcelExportParams,
  useTableWidgetExportFeature,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { useRef } from 'react';

export type FinancialTableSinglePeriodWidgetProps = {
  id: string;
  position: ReportDashboardSectionPosition;
  widgetType: 'financial_table_single_period';
  title: string;
  widgetConfig?: {
    period_types: FinancialTableSinglePeriodPeriodType[];
    ag_grid_config: {
      columnDefs?: [];
    };
  };
  defaultOptions?: {
    availablePeriodTypes: FinancialTableSinglePeriodPeriodType[];
    date: string;
    periodType: FinancialTableSinglePeriodPeriodType;
    groupingType: 'assets' | 'segments';
  };
  fontSize?: number | null;
};

export type FinancialTableSingePeriodWidgetContext =
  DisplayedGroupsWidgetContext & {
    assets?: IAsset[];
  };
export type FinancialTableSinglePeriodWidgetState = DateWidgetState &
  DisplayedGroupsWidgetState &
  ColumSizeWidgetState &
  PeriodTypeWidgetState & {
    excludedLegalEntityIds?: LegalEntity['id'][];
  };

export const FinancialTableSinglePeriodWidget = (
  props: WidgetProps<
    FinancialTableSingeDateWidgetNS.SnapshotData,
    FinancialTableSinglePeriodWidgetProps
  > &
    WidgetStateProps<FinancialTableSinglePeriodWidgetState> &
    WidgetContextProps<FinancialTableSingePeriodWidgetContext>,
) => {
  const {
    data,
    mode,
    isError,
    isLoading,
    isFetching,
    widgetSection,
    state,
    onStateChange,
    dashboardType,
    context,
  } = props;

  const wrapperDivRef = useRef<HTMLDivElement>(null);

  const gridRef = useRef<AgGridReact>(null);
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const vizConfig = data?.vizConfig ?? {};
  const headerBackground = vizConfig?.header_background;
  const columns = data?.columns ?? [];
  const rows = data?.rows ?? [];
  const hideHeader = data?.hideHeader ?? false;
  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const excludedLegalEntityIds = state?.excludedLegalEntityIds ?? [];

  const widgetStateFullScreenProps = useWidgetFullScreen(wrapperDivRef);

  const { rowsDisplayOptionsExcelStyles: dynamicExcelStyles, cellClassRules } =
    useFinancialTableWidgetsRowsDisplayOptionsExcelStyles({
      headerBackground,
      rows,
    });

  const columnVisibilityFeature = useFinancialWidgetColumnsVisibilityState({
    data,
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const autoColumnDef = useFinancialTableWidgetAutoColumDef({
    headerBackground,
    widgetSectionFontSize,
    data,
    cellClassRules,
  });

  const columnDefs = useFinancialTableWidgetColDef({
    data,
    mode,
    dashboardType,
    widgetSection,
    cellClassRules,
    vizConfig,
  });

  const defaultColDef = useFinancialTableWidgetDefaultColDef({
    isCompactSelected: state.selectedColumnSize === 'compact',
    deps: [state.displayedGroups],
  });

  const rowData = useFinancialTableWidgetRowData({
    data,
  });

  const exportFeature = useTableWidgetExportFeature(
    {
      mode,
      gridRef,
    },
    () => ({
      ...getDefaultTableExcelExportParams({
        gridRef,
        fileName: widgetSection.title,
      }),
      sheetName: state.date,
    }),
  );

  const excelStyles = useFinancialTableWidgetExcelStyles({
    columns,
    rowsDisplayOptionsExcelStyles: dynamicExcelStyles,
  });

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header className="justify-normal gap-tw-4">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          <div className="grow" />
          <WidgetStatePeriod state={state} onStateChange={onStateChange} />
          <WidgetStateReckonerPeriodType
            state={state}
            onStateChange={onStateChange}
            periodTypes={
              widgetSection.widgetConfig?.period_types ??
              widgetSection.defaultOptions?.availablePeriodTypes ??
              []
            }
          />
          {!widgetStateFullScreenProps.isFullscreen &&
            context.columnVisibilityEnabled && (
              <WidgetStateColumns
                state={state}
                onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                isDataLoading={Boolean(isLoading)}
                onColumnStateChange={columnVisibilityFeature.onChange}
              />
            )}
          {showLegalEntitiesDropdownByWidgetContext({
            ...context,
            dashboardType,
          }) && (
            <WidgetStateLegalEntities
              context={context}
              state={state}
              onStateChange={(leId) => {
                onStateChange({
                  excludedLegalEntityIds: addOrRemoveItemInList(
                    leId,
                    excludedLegalEntityIds,
                  ),
                });
              }}
            />
          )}
          <exportFeature.ExportButtonComponent />

          <WidgetStateFullScreenIconButton {...widgetStateFullScreenProps} />
        </DashboardWidgetCard.Header>
      )}
      {mode === 'pdf' &&
        widgetSection.title &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {!isLoading && !isError && (
        <WidgetTable
          defaultColGroupDef={undefined}
          ref={gridRef}
          headerBackground={headerBackground}
          hideHeader={hideHeader}
          mode={mode}
          expensivePropSuppressColumnVirtualisation
          columnDefs={columnDefs}
          autoGroupColumnDef={autoColumnDef}
          rowData={rowData}
          defaultColDef={defaultColDef}
          excelStyles={excelStyles}
          onRowDataChanged={columnSizeFeature.handleRowDataChanged}
          defaultParams={{
            sizeColumnsToFit: true,
          }}
        />
      )}
    </DashboardWidgetTableCard>
  );
};
