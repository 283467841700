import { CssVar } from '@/shared/config/cssVar';
import { contrastingTextColor } from '@/shared/lib/colors';
import { cn } from '@/shared/lib/css/cn';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { rangePercentile } from '@/shared/lib/statistics';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  IHeaderParams,
} from 'ag-grid-community';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import {
  DEFAULT_GROUP_BG_COLOR,
  DEFAULT_WIDGET_TABLE_PROPS,
  PinColumnAction,
  resolveComparisonTextColor,
  WidgetTableGroupHeaderGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { resolveHeaderWithSubheaderComponentProps } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import {
  extractGradientFromThreshold,
  findMaxThreshold,
  findMinThreshold,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GradientField';
import {
  HeaderComponentWithSubHeader,
  HeaderWithSubHeaderParams,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import {
  TableVizConfig,
  TableVizConfigColorBackground,
  TableVizConfigColumn,
  TableVizConfigColumnGradientThreshold,
  TableVizConfigColumnGroup,
  TableVizConfigComparison,
  TableVizConfigGradientBackground,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  buildExcelStyleId,
  TABLE_EXCEL_STYLE_IDS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableHeaderGroupComponentParams } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetHeaderGroup';
import {
  findColumnByKey,
  zeroAndNullToTheEndComparator,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import * as d3 from 'd3';
import { orderBy } from 'lodash-es';
import { CSSProperties } from 'react';
import { IconsId } from 'types/sre-icons';

const COLORED_BORDER_WIDTH = '2px';
const TOTAL_COLOR_STYLES = {
  background: CssVar.neutral850,
  color: CssVar.neutral000,
  borderColor: CssVar.neutral700,
};
export const isTotalRow = (params: ICellRendererParams) =>
  params.data?.type === 'total';
const shouldPaintBackground = (params: ICellRendererParams) =>
  params.context.groupingType === 'assets'
    ? params.data?.type === 'asset'
    : params.data?.type === 'segment';

const resolveBorderStyles = ({
  columnSettings,
  params,
  totalColors,
}: {
  columnSettings: TableVizConfigColumn;
  params: ICellRendererParams;
  totalColors: typeof TOTAL_COLOR_STYLES;
}): CSSProperties => {
  const isTotal = isTotalRow(params);
  const sideBordersWidth = columnSettings.border_color
    ? COLORED_BORDER_WIDTH
    : undefined;
  const sideBordersColor =
    columnSettings.border_color ??
    (isTotal ? totalColors.borderColor : undefined);
  return {
    borderRightWidth: sideBordersWidth,
    borderLeftWidth: sideBordersWidth,
    borderBottomWidth: isTotal ? COLORED_BORDER_WIDTH : undefined,
    borderLeftColor: sideBordersColor,
    borderRightColor: sideBordersColor,
    borderBottomColor: isTotal
      ? columnSettings.border_color ?? totalColors.borderColor
      : undefined,
  };
};

export const resolveBackgroundAndTextColor = ({
  comparison,
  background,
  params,
  direction = 'column',
  totalColors,
  ...args
}: {
  params: ICellRendererParams;
  background?:
    | TableVizConfigGradientBackground
    | TableVizConfigColorBackground
    | undefined;
  comparison?: TableVizConfigComparison;
  isTotalRow?: (params: ICellRendererParams) => boolean;
  shouldColorText?: (params: ICellRendererParams) => boolean;
  shouldPaintBackground?: (params: ICellRendererParams) => boolean;
  direction?: 'row' | 'column';
  totalColors?: typeof TOTAL_COLOR_STYLES;
}) => {
  if (args.isTotalRow?.(params)) {
    return totalColors;
  }
  if (comparison && args.shouldColorText?.(params)) {
    return {
      color: resolveComparisonTextColor(
        {
          compareTo: comparison.to_col_id,
          rule: comparison.rule,
        },
        params,
        (rowData, key) => rowData?.[key],
      ),
    };
  }

  if (background && args.shouldPaintBackground?.(params)) {
    const getBackgroundAndContrastingTextColor = (color: string) => {
      return {
        background: color,
        color: contrastingTextColor(color, {
          dark: 'var(--next-get-table-cell-color)',
          light: CssVar.neutral000,
        }),
      };
    };
    if (background.type === 'color') {
      return getBackgroundAndContrastingTextColor(background.color);
    }
    const { value } = params;

    if (value == null || (background.ignore_zeros && value === 0)) {
      return {};
    }

    const getBackgroundColor = () => {
      const { minMaxValues } = params.context;
      const key =
        direction === 'row'
          ? params.data.key?.toString()
          : params.column!.getColId();

      const dataMin = background.ignore_zeros
        ? minMaxValues[key].minWithoutZero
        : minMaxValues[key].min;
      const dataMax = background.ignore_zeros
        ? minMaxValues[key].maxWithoutZero
        : minMaxValues[key].max;

      const { threshold } = background;
      const minThreshold = findMinThreshold(threshold);
      const maxThreshold = findMaxThreshold(threshold);

      if (value === dataMax && maxThreshold) {
        return maxThreshold.color;
      }

      if (value === dataMin && minThreshold) {
        return minThreshold.color;
      }

      const getDomainByThreshold = (
        t: TableVizConfigColumnGradientThreshold,
      ) => {
        if (t.type === 'min') {
          return dataMin;
        }
        if (t.type === 'max') {
          return dataMax;
        }
        if (t.type === 'percentile') {
          return rangePercentile({
            value: t.value!,
            rangeMin: dataMin,
            rangeMax: dataMax,
          });
        }
        return t.value!;
      };
      const gradient = extractGradientFromThreshold(threshold);
      const domain = threshold.map(getDomainByThreshold);
      const gradientScale = d3.scaleLinear().domain(domain).range(gradient);
      return gradientScale(value);
    };

    const backgroundColor = getBackgroundColor();

    return getBackgroundAndContrastingTextColor(backgroundColor);
  }

  return {};
};

export class ColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  mode: WidgetViewMode;
  totalColors: typeof TOTAL_COLOR_STYLES = TOTAL_COLOR_STYLES;
  onPinColumn?: (colId: string) => unknown;

  subHeaderName?: (params: {
    mode: WidgetViewMode;
    columnSettings: TableVizConfigColumn;
    params: IHeaderParams;
    column?: Column;
  }) => string;

  constructor({
    mode,
    onPinColumn,
  }: {
    mode: WidgetViewMode;
    onPinColumn?: (colId: string) => unknown;
  }) {
    this.mode = mode;
    this.onPinColumn = onPinColumn;
  }

  withTotalColors(totalColors: typeof TOTAL_COLOR_STYLES) {
    this.totalColors = totalColors;
    return this;
  }

  withSubHeaderName(
    subHeaderName: typeof ColDefBuilder.prototype.subHeaderName,
  ) {
    this.subHeaderName = subHeaderName;
    return this;
  }

  build({
    column,
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): ColDef {
    return {
      cellRenderer: DEFAULT_WIDGET_TABLE_PROPS.defaultColDef.cellRenderer,
      headerComponent: HeaderComponentWithSubHeader,
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      flex: undefined,
      sortable: this.mode != 'pdf',
      headerName: column?.label,
      initialHide: columnSettings.hidden,
      colId: columnSettings.col_id,
      field: columnSettings.key,
      maxWidth: columnSettings?.max_width,
      minWidth: columnSettings?.min_width,
      columnGroupShow: columnSettings.column_group_show ?? undefined,
      initialSort: columnSettings.initial_sort,
      comparator: zeroAndNullToTheEndComparator,
      cellClassRules: {
        [TABLE_EXCEL_STYLE_IDS.totalBackground]: (params) =>
          params.data?.type === 'total',
      },
      cellClass: ({ colDef }) =>
        colDef.colId && buildExcelStyleId(colDef.colId),
      cellRendererParams: (params: ICellRendererParams) => {
        const isTotal = isTotalRow(params);
        const { comparison, align } = columnSettings;
        const compareColor = resolveComparisonTextColor(
          comparison
            ? {
                compareTo: comparison.to_col_id,
                rule: comparison.rule,
              }
            : {},
          params,
          (rowData, key) => rowData?.[key],
        );

        return {
          labelColor: isTotal ? compareColor : undefined,
          classes: {
            inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic, {
              '!justify-end': align === 'right',
              '!justify-center': align === 'center',
              '!justify-start': align === 'left',
              // workaround for truncated last row in pdf
              '!items-start': this.mode === 'pdf' && params.node.lastChild,
            }),
          },
          value:
            isTotal && params.colDef?.type === 'date' ? null : params.value,
          styles: {
            ...resolveBackgroundAndTextColor({
              background: columnSettings.background,
              comparison,
              params,
              shouldPaintBackground,
              isTotalRow,
              totalColors: this.totalColors,
              shouldColorText: () => true,
            }),
            ...resolveBorderStyles({
              columnSettings,
              params,
              totalColors: this.totalColors,
            }),
            fontWeight: isTotal ? 'bold' : columnSettings.font_weight,
          },
        };
      },
      headerComponentParams: (params: IHeaderParams) => {
        const { hide_subtitle, hide_title } = columnSettings.header ?? {};
        const subHeaderName = this.subHeaderName?.({
          column,
          columnSettings,
          params,
          mode: this.mode,
        });
        const parent = params.column.getParent();

        const colGroupDef = parent?.getColGroupDef();
        const { style: groupStyle } =
          colGroupDef?.headerGroupComponentParams ?? {};
        const borderColor =
          columnSettings.border_color ?? groupStyle?.borderColor;
        return {
          ...resolveHeaderWithSubheaderComponentProps({
            headerName: column?.label,
            subHeaderName,
            hide_subtitle,
            hide_title,
          }),
          style: {
            ...groupStyle,
            borderWidth: columnSettings.border_color
              ? COLORED_BORDER_WIDTH
              : undefined,
            borderTopColor: borderColor,
            borderLeftColor: borderColor,
            borderRightColor: borderColor,
            backgroundColor:
              groupStyle?.backgroundColor ?? DEFAULT_GROUP_BG_COLOR,
          },
          actions: (
            <PinColumnAction
              {...params}
              mode={this.mode}
              onPinColumn={this.onPinColumn}
            />
          ),
        } satisfies HeaderWithSubHeaderParams;
      },
      ...(columnSettings.value_display_options &&
        getDefaultAgGridNumberColDef({
          ...columnSettings.value_display_options,
          fallbackCondition: (value) =>
            value === 0 ||
            (columnSettings.comparison?.hide_negative_values === true &&
              value < 0),
        })),
    };
  }
}

export class ColGroupDefBuilder {
  mode: WidgetViewMode;
  headerName: (params: {
    group: TableVizConfigColumnGroup;
    mode: WidgetViewMode;
  }) => string;

  constructor({ mode }: { mode: WidgetViewMode }) {
    this.mode = mode;
  }

  withHeaderName(
    headerName: (params: {
      group: TableVizConfigColumnGroup;
      mode: WidgetViewMode;
    }) => string,
  ) {
    this.headerName = headerName;
    return this;
  }

  build(group: TableVizConfigColumnGroup): Omit<ColGroupDef, 'children'> {
    const headerName =
      this.headerName?.({
        group,
        mode: this.mode,
      }) ?? group.header_name;
    return {
      groupId: `group-${group.group_id}`,
      headerGroupComponent: WidgetTableGroupHeaderGroup,
      headerGroupComponentParams: {
        icon: group.icon as IconsId,
        style: {
          backgroundColor: group.background ?? DEFAULT_GROUP_BG_COLOR,
          borderColor: group.border_color,
        },
        mode: this.mode,
      } satisfies WidgetTableHeaderGroupComponentParams,
      headerName,
    };
  }
}

export class ColumnDefsBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  columnDefs: (ColDef | ColGroupDef)[];
  viz_config: TableVizConfig;
  mode: WidgetViewMode;
  colDefBuilder: ColDefBuilder<Column>;
  colGroupDefBuilder: ColGroupDefBuilder;

  constructor({
    viz_config,
    mode,
    colDefBuilder,
    colGroupDefBuilder,
  }: {
    viz_config: TableVizConfig;
    mode: WidgetViewMode;
    colGroupDefBuilder: ColGroupDefBuilder;
    colDefBuilder: ColDefBuilder<Column>;
  }) {
    this.colGroupDefBuilder = colGroupDefBuilder;
    this.colDefBuilder = colDefBuilder;
    this.columnDefs = [];
    this.viz_config = viz_config;
    this.mode = mode;
  }

  build({ columns }: { columns: Column[] }) {
    if (!columns.length) {
      return [];
    }
    const transformColumnSettingsToColDef = (cs: TableVizConfigColumn) => {
      const column = findColumnByKey(columns, Number(cs.key))!;

      return this.colDefBuilder.build({
        column,
        columnSettings: cs,
      });
    };
    const ungroupedColumns = orderBy(
      this.viz_config.columns.filter((column) => !column.group_id),
      'order',
    );
    const colGroupDefs = orderBy(this.viz_config.column_groups, 'order').map(
      (group) => {
        const columnsSettings = orderBy(
          this.viz_config.columns,
          'order',
        ).filter((c) => c.group_id === group.group_id);
        return {
          ...this.colGroupDefBuilder.build(group),
          children: columnsSettings.map(transformColumnSettingsToColDef),
        };
      },
    );

    return [
      ...ungroupedColumns.map(transformColumnSettingsToColDef),
      ...colGroupDefs,
    ].filter((column) => column !== null) as (ColDef | ColGroupDef)[];
  }
}
