import { STATIC_DATE_PICKER_PROPS } from '@/bundles/REturn/components/Ownership/modals/consts';
import InputNumber from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import { VariableValueType } from '@/bundles/Shared/entities/formula';
import { VALUE_TYPE_ICON } from '@/entities/report/manualVariable/config';
import { ReportManualVariableLegalEntityValue } from '@/entities/report/manualVariable/model';
import {
  convertDecimalToPercentage,
  convertPercentageToDecimal,
  formatToDateStringForRequest,
} from '@/shared/lib/converters';
import { DateSSN } from '@/shared/lib/dateSSN/ssn';
import { IconButton } from '@/stories';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

const ReactDatePickerWithState = ({
  defaultValue,
  onUpdate,
}: {
  defaultValue: string | Date | null;
  onUpdate: (date: Date | null) => void;
}) => {
  const [value, setValue] = useState<Date | null>(
    defaultValue ? dayjs(defaultValue).toDate() : null,
  );

  return (
    <ReactDatePicker
      {...STATIC_DATE_PICKER_PROPS}
      selected={value ?? null}
      onChange={(date) => {
        setValue(date);
      }}
      onBlur={() => {
        onUpdate(value);
      }}
    />
  );
};
export const useManualVariablesLEValuesColumnDefs = ({
  valueType,
  actions,
  deps,
}: {
  valueType: VariableValueType | null;
  actions: {
    onRemove: (id: ReportManualVariableLegalEntityValue['id']) => void;
    onUpdate: (v: ReportManualVariableLegalEntityValue) => void;
  };
  deps: React.DependencyList;
}) => {
  return useMemo<IColumn<ReportManualVariableLegalEntityValue>[]>(() => {
    return [
      {
        dataField: 'dateFrom',
        text: 'Date From',
        formatter: ({ row: item }) => (
          <ReactDatePickerWithState
            defaultValue={item.dateFrom}
            onUpdate={(date) => {
              actions.onUpdate({
                ...item,
                dateFrom: date ? formatToDateStringForRequest(date) : null,
              });
            }}
          />
        ),
      },
      {
        dataField: 'dateTo',
        text: 'Date To',
        formatter: ({ row: item }) => (
          <ReactDatePickerWithState
            defaultValue={item.dateTo}
            onUpdate={(date) => {
              actions.onUpdate({
                ...item,
                dateTo: date ? formatToDateStringForRequest(date) : null,
              });
            }}
          />
        ),
      },
      {
        dataField: 'value',
        text: 'Value',
        formatter: ({ row }) => {
          if (valueType === 'percentage') {
            return (
              <InputNumber
                defaultValue={convertDecimalToPercentage(row.value)}
                size="m"
                rightIcon={valueType ? VALUE_TYPE_ICON[valueType] : undefined}
                onBlur={(value) => {
                  actions.onUpdate({
                    ...row,
                    value: value ? convertPercentageToDecimal(value) : 0,
                  });
                }}
              />
            );
          }
          if (valueType === 'date') {
            const currentDate = new DateSSN(row.value).toDate();
            return (
              <ReactDatePickerWithState
                defaultValue={currentDate}
                onUpdate={(date) => {
                  const newValue = new DateSSN(date ?? new Date()).toNumber();
                  actions.onUpdate({
                    ...row,
                    value: newValue,
                  });
                }}
              />
            );
          }
          return (
            <InputNumber
              defaultValue={row.value}
              size="m"
              rightIcon={valueType ? VALUE_TYPE_ICON[valueType] : undefined}
              onBlur={(value) => {
                actions.onUpdate({
                  ...row,
                  value: value ?? 0,
                });
              }}
            />
          );
        },
      },
      {
        dataField: 'actions',
        headerClasses: 'w-[56px]',
        text: '',
        formatter: ({ row }) => (
          <>
            <IconButton
              iconName="trash"
              size="m"
              onClick={() => actions.onRemove(row.id)}
            />
          </>
        ),
      },
    ];
  }, deps);
};
