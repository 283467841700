import {
  SettingsReportComparisonDashboardDateFilter,
  SettingsReportObjectDashboardDateFilter,
} from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportDashboardDateFilter';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { ComparisonDashboardAssetFilter } from '@/bundles/Shared/features/dashboard/object/filter/byAsset/ui/ComparisonDashboardAssetFilter';
import { ReportDashboardDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { ROUTES_ROOT, useQueryParams } from '@/shared/lib/hooks/useNavigation';
import { AnimationLoader, Button } from '@/stories';
import {
  NO_BOARD_ID,
  useReportSettingsDashboardById,
} from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { SettingsReportDashboardWidgetConfigEditor } from 'bundles/Settings/components/REport/Dashboards/Dashboard/Widget/SettingsReportDashboardWidgetConfigEditor';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { ReactComponent as WidgetSvg } from 'bundles/Shared/components/layouts/dashboard/WidgetIcon.svg';
import { WidgetFlyPanel } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import {
  buildLayoutsId,
  DashboardContext,
  DashboardContextValue,
  loadInitialWidgetState,
  ReportDashboardType,
  selectReportEagleEyeDashboardSettingsById,
  selectReportObjectDashboardMetadataById,
  updateDashboardWidgetState,
  useReportEagleEyeDashboardSettingsById,
} from 'bundles/Shared/entities/dashboard';
import { ObjectDashboardAssetFilter } from 'bundles/Shared/features/dashboard/object/filter/byAsset/ui/ObjectDashboardAssetFilter';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
  useWidgetPreviewData,
  useWidgetScreenParams,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib';
import { FormulasAndVariablesWorkspace } from 'bundles/Shared/widgets/formula/panel';
import { assertsQueryDataLoadedBefore } from 'lib/typeHelpers/redux/rtkApiHelpers';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { CrumbSeparator } from 'stories/Breadcrumbs/Breadcrumbs';
import { UnknownRecord } from 'type-fest/source/internal';

const LoadedWidget = ({ dashboard }: { dashboard: ReportDashboardDto }) => {
  const { boardId, dashboardId, widgetId } = useWidgetScreenParams();

  const { assetId } = useQueryParams<{
    assetId?: string;
  }>();
  const dispatch = useAppDispatch();

  const { value: isCodeEditorOpen, toggle: toggleCodeEditorOpen } =
    useBoolean(false);
  const { dashboard: eagleEyeDashboard, data: eagleEyeData } =
    useReportEagleEyeDashboardSettingsById(
      {
        dashboardId,
      },
      {
        skip:
          dashboard == null || dashboard.type !== ReportDashboardType.EAGLE_EYE,
      },
    );

  const resolveStateSelector = () => {
    switch (dashboard?.type) {
      case ReportDashboardType.OBJECT: {
        return selectReportObjectDashboardMetadataById;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return selectReportEagleEyeDashboardSettingsById;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return selectReportComparisonDashboardMetadataById;
      }
      default: {
        return () => null;
      }
    }
  };
  const stateSelector = resolveStateSelector();
  const dashboardState = useAppSelector((state) =>
    stateSelector(
      state,
      buildLayoutsId({
        dashboardId,
        boardId: boardId === NO_BOARD_ID ? undefined : boardId,
      }),
    ),
  );
  const widgetInitialState = dashboardState?.widgetsStateInitial?.[widgetId];
  const { data, widget, isLoading } = useWidgetConfig();
  const [updateConfig] = useUpdateWidgetConfig(widget?.widgetType);
  const {
    isLoading: isWidgetDataLoading,
    isFetching: isWidgetDataFetching,
    isError: isWidgetDataError,
    data: widgetData,
  } = useWidgetPreviewData();

  if (isLoading || data == null) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  assertsQueryDataLoadedBefore(data);

  // @ts-expect-error
  const { ConfigComponent, Component, title } =
    WIDGETS_CONFIG_MAP[widget.widgetType];

  const handleStateChange = (newState: UnknownRecord) => {
    dispatch(
      updateDashboardWidgetState({
        id: widgetId,
        boardId,
        widgetState: newState,
        dashboardId,
      }),
    );
  };

  return (
    <FormulasAndVariablesWorkspace>
      <DashboardLayout className="h-full">
        <DashboardLayout.Header className="h-auto bg-neutral-000">
          <DashboardLayout.Header.Title
            classes={{
              subtitle: 'flex items-center gap-tw-2 text-neutral-550',
            }}
            subtitle={
              <>
                {data.dashboardName}
                <CrumbSeparator />
                {data.boardName}
              </>
            }
            title={
              <>{(widget as { title: string } | undefined)?.title ?? ''}</>
            }
          />
          <div className="grow" />
          {!isEmpty(widgetInitialState) && (
            <Button
              onClick={() =>
                dispatch(
                  loadInitialWidgetState({
                    dashboardId,
                    boardId,
                    id: widgetId,
                  }),
                )
              }
              variant="secondary"
            >
              Load default state
            </Button>
          )}
        </DashboardLayout.Header>
        <DashboardLayout.Body className="relative">
          <DashboardLayout.Grid>
            <div
              className={cn(
                'absolute top-tw-6 flex items-center gap-tw-2',
                isCodeEditorOpen && 'left-[556px]',
              )}
            >
              {dashboard?.type === ReportDashboardType.OBJECT && (
                <>
                  <ObjectDashboardAssetFilter
                    assetId={Number(assetId!)}
                    pathParams={{
                      dashboardId,
                      widgetId,
                      boardId,
                    }}
                    path={
                      ROUTES_ROOT.settings.report.dashboards.dashboard.widget
                        .fullPath
                    }
                  />
                  <SettingsReportObjectDashboardDateFilter />
                </>
              )}
              {dashboard?.type === ReportDashboardType.COMPARISON_MODE && (
                <>
                  <ComparisonDashboardAssetFilter
                    assetId={Number(assetId!)}
                    pathParams={{
                      dashboardId,
                      widgetId,
                      boardId,
                    }}
                    path={
                      ROUTES_ROOT.settings.report.dashboards.dashboard.widget
                        .fullPath
                    }
                  />

                  <SettingsReportComparisonDashboardDateFilter />
                </>
              )}
              <Button
                onClick={toggleCodeEditorOpen}
                iconClasses={cn(isCodeEditorOpen && 'text-info-055')}
                iconName={isCodeEditorOpen ? 'panelLeft' : 'panelRight'}
                size="m"
                variant="secondary"
              >
                JSON Editor
              </Button>
            </div>
            <div
              className={cn(
                'flex h-full w-[70%] items-center justify-center gap-tw-4 pl-tw-4',
                isCodeEditorOpen && 'w-full justify-start',
              )}
            >
              {isCodeEditorOpen && (
                <SettingsReportDashboardWidgetConfigEditor />
              )}
              <div className="grow">
                <Component
                  widgetId={widgetId}
                  className="h-[500px]"
                  dashboardId={dashboardId}
                  // @ts-expect-error
                  widgetSection={widget}
                  // @ts-expect-error
                  context={{
                    assets: eagleEyeDashboard?.assets ?? [],
                    segments: eagleEyeDashboard?.segments ?? [],
                    comparison:
                      dashboard.type === ReportDashboardType.COMPARISON_MODE,
                    ...eagleEyeData?.meta,
                  }}
                  dashboardType={dashboard.type}
                  boardId={boardId}
                  mode="edit"
                  // @ts-expect-error
                  state={dashboardState?.widgetsState?.[widgetId] ?? {}}
                  onStateChange={handleStateChange}
                  // @ts-expect-error
                  settings={widget.widgetConfig}
                  onSettingsChange={(config) => updateConfig({ config })}
                  // @ts-expect-error
                  data={widgetData}
                  isLoading={isWidgetDataLoading}
                  isError={isWidgetDataError}
                  isFetching={isWidgetDataFetching}
                />
              </div>
            </div>
          </DashboardLayout.Grid>
          <WidgetFlyPanel className="top-tw-6">
            <WidgetFlyPanel.Header
              label="Widget: "
              title={title}
              icon={<WidgetSvg />}
            />
            {ConfigComponent && <ConfigComponent />}
          </WidgetFlyPanel>
        </DashboardLayout.Body>
      </DashboardLayout>
    </FormulasAndVariablesWorkspace>
  );
};
export function SettingsReportDashboardWidget() {
  const params = useWidgetScreenParams();

  const { data: dashboardData, isLoading } = useReportSettingsDashboardById(
    params.dashboardId,
  );
  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId: params.dashboardId,
      dashboardType:
        (dashboardData?.type as ReportDashboardType) ??
        ReportDashboardType.OBJECT,
      boardId: params.boardId,
    }),
    [dashboardData, params],
  );

  if (isLoading || dashboardData == null || params.boardId == null) {
    return <AnimationLoader />;
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <LoadedWidget dashboard={dashboardData} />
    </DashboardContext.Provider>
  );
}
