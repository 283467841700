import { createSelector } from '@reduxjs/toolkit';
import {
  DashboardFilterObject,
  ReportDashboardAsset,
  ReportDashboardEagleEyeAsset,
  ReportDashboardLegalEntity,
  ReportDashboardSectionPositionWithId,
  ReportDashboardSegment,
  ReportDashboardType,
  ReportEagleEyeDashboard,
  useGetApiReportScoreboardsByIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
} from 'bundles/Shared/entities/dashboard';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import {
  useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportComparisonDashboardsMetaQuery,
  useGetApiSettingsReportEagleEyeDashboardsByIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsMetaQuery,
  useGetApiSettingsReportObjectDashboardsMetaQuery,
} from 'bundles/Shared/shared/api/dashboardSettingsEnhancedApi';
import { useGetApiSettingsReportEagleEyeDashboardsFilterSetsMetaQuery } from 'bundles/Shared/shared/api/dashboardSettingsFilterSetsEnhancedApi';
import {
  DashboardLayoutDto,
  GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse,
  GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse,
  GetApiSettingsReportEagleEyeDashboardsMetaApiResponse,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { orderBy, uniqBy } from 'lodash-es';
import { useMemo } from 'react';

export * from 'bundles/Shared/shared/api/dashboardSettingsEnhancedApi';
export * from 'bundles/Shared/shared/api/dashboardsGeneratedApi';

const mapAssetToObject = (
  asset: ReportDashboardAsset,
): DashboardFilterObject => ({
  id: asset.id,
  name: asset.name,
  legalEntities: asset.legalEntities,
  leClassification: [
    ...new Set(
      asset.legalEntities?.map((le) => le.classification).filter(Boolean),
    ),
  ] as LeClassification[],
  type: 'asset',
});

const mapSegmentToObject = (
  segment: ReportDashboardSegment,
): DashboardFilterObject => ({
  id: segment.id,
  name: segment.title,
  type: 'segment',
  legalEntities: segment.legalEntities,
  leClassification: [
    ...new Set(
      segment.legalEntities?.map((le) => le.classification).filter(Boolean),
    ),
  ] as LeClassification[],
});

const useReportDashboardSelectOrderedByName = () => {
  return useMemo(() => {
    const emptyArray: ReportDashboardAsset[] = [];
    return createSelector(
      (state: { data?: { assets?: ReportDashboardAsset[] } } | undefined) =>
        state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardAsset[]) => orderBy(assets, 'name'),
    );
  }, []);
};

const useReportDashboardSelectObjects = () => {
  return useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: { data: { assets: ReportDashboardAsset[] } }) =>
        state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardAsset[]) =>
        assets.map(mapAssetToObject) ?? emptyArray,
    );
  }, []);
};

export const useReportObjectDashboardAssetsQuery = () => {
  const selectOrderedByName = useReportDashboardSelectOrderedByName();
  const selectObjects = useReportDashboardSelectObjects();

  return useGetApiSettingsReportObjectDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: (data?.assets ?? []) as ReportDashboardAsset[],
        reportBuilderTemplates: data?.reportBuilderTemplates ?? [],
        assetObjects: selectObjects({ data }),
        assetsOrderedByName: selectOrderedByName({ data }),
      };
    },
  });
};
export const useReportComparisonDashboardAssetsQuery = () => {
  const selectOrderedByName = useReportDashboardSelectOrderedByName();
  const selectObjects = useReportDashboardSelectObjects();

  return useGetApiSettingsReportComparisonDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: (data?.assets ?? []) as ReportDashboardAsset[],
        assetObjects: selectObjects({ data }),
        assetsOrderedByName: selectOrderedByName({ data }),
      };
    },
  });
};

export const useReportEagleEyeDashboardFiltersObjectQuery = () => {
  const selectObjects = useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: {
        data: GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse;
      }) => state?.data,
      (
        data: GetApiSettingsReportEagleEyeDashboardsFilterSetsMetaApiResponse,
      ) =>
        data
          ? ([
              ...data.assets.map(mapAssetToObject),
              ...data.segments.map(mapSegmentToObject),
            ] as DashboardFilterObject[])
          : emptyArray,
    );
  }, []);
  return useGetApiSettingsReportEagleEyeDashboardsFilterSetsMetaQuery(
    undefined,
    {
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          objects: selectObjects({ data }),
        };
      },
    },
  );
};

export const useReportEagleEyeDashboardAssetsQuery = () => {
  const selectObjects = useMemo(() => {
    const emptyArray: DashboardFilterObject[] = [];
    return createSelector(
      (state: {
        data: GetApiSettingsReportEagleEyeDashboardsMetaApiResponse;
      }) => state?.data,
      (data) =>
        data
          ? ([
              ...data.assets.map(mapAssetToObject),
              ...data.segments.map(mapSegmentToObject),
            ] as DashboardFilterObject[])
          : emptyArray,
    );
  }, []);

  const selectOrderedByName = useMemo(() => {
    const emptyArray: ReportDashboardEagleEyeAsset[] = [];
    return createSelector(
      (
        state:
          | { data?: { assets?: ReportDashboardEagleEyeAsset[] } }
          | undefined,
      ) => state?.data?.assets ?? emptyArray,
      (assets: ReportDashboardEagleEyeAsset[]) => orderBy(assets, 'name'),
    );
  }, []);

  const legalEntities = useMemo(() => {
    return createSelector(
      (
        state:
          | { data?: { assets?: ReportDashboardEagleEyeAsset[] } }
          | undefined,
      ) => {
        return state?.data?.assets ?? [];
      },
      (assets: ReportDashboardEagleEyeAsset[]) => {
        const map = new Map<
          string,
          ReportDashboardLegalEntity & {
            asset: ReportDashboardEagleEyeAsset;
          }
        >();
        assets.forEach((asset) => {
          asset.legalEntities.forEach((le) => {
            map.set(le.id, {
              ...le,
              asset,
            });
          });
        });
        return [...map.entries()].map(([, value]) => value);
      },
    );
  }, []);

  return useGetApiSettingsReportEagleEyeDashboardsMetaQuery(undefined, {
    selectFromResult: ({ data, ...result }) => {
      return {
        ...result,
        assets: data?.assets ?? [],
        assetsOrderedByName: selectOrderedByName({ data }),
        legalEntities: legalEntities({ data }),
        objects: selectObjects({ data }),
      };
    },
  });
};

export const useReportEagleEyeDashboardSettingsById = (
  {
    dashboardId,
  }: {
    dashboardId: ReportEagleEyeDashboard['id'];
  },
  args?: Parameters<
    typeof useGetApiSettingsReportEagleEyeDashboardsByIdQuery
  >[1],
) => {
  return useGetApiSettingsReportEagleEyeDashboardsByIdQuery(
    {
      id: dashboardId,
    },
    {
      ...args,
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          data,
          dashboard: data as unknown as ReportEagleEyeDashboard,
        };
      },
    },
  );
};

export const useReportEagleEyeDashboardById = ({
  dashboardId,
}: {
  dashboardId: ReportEagleEyeDashboard['id'];
}) => {
  return useGetApiReportScoreboardsByIdQuery(
    {
      id: dashboardId,
    },
    {
      selectFromResult: ({ data, ...result }) => {
        return {
          ...result,
          data,
          dashboard: data?.item as unknown as ReportEagleEyeDashboard,
        };
      },
    },
  );
};

export const useDashboardCopyableWidgetSections = ({
  dashboardId,
  boardId,
  dashboardType,
}: {
  dashboardId: string;
  dashboardType: ReportDashboardType;
  boardId?: string;
}) => {
  const query = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery;
      }
    }
  }, [
    dashboardType,
    useGetApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
    useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
    useGetApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdCopyableWidgetSectionsQuery,
  ]);

  const selectDashboards = useMemo(() => {
    const emptyArray =
      [] as GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse[number]['board']['dashboard'];
    return createSelector(
      ({
        data,
      }: {
        data?: GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse;
      }) => data,
      (sections) =>
        uniqBy(sections?.map((s) => s.board.dashboard) ?? emptyArray, 'id'),
    );
  }, []);

  const selectBoards = useMemo(() => {
    const emptyArray =
      [] as GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse[number]['board']['dashboard'];
    return createSelector(
      ({
        data,
      }: {
        data?: GetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdCopyableWidgetSectionsApiResponse;
      }) => data,
      (sections) => uniqBy(sections?.map((s) => s.board) ?? emptyArray, 'id'),
    );
  }, []);
  return query(
    {
      boardId: boardId!,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      objectDashboardId: dashboardId,
    },
    {
      skip: !boardId,
      selectFromResult: ({ data, ...result }) => ({
        ...result,
        data,
        dashboards: selectDashboards({ data }),
        boards: selectBoards({ data }),
      }),
    },
  );
};

export const mapLayoutToLayoutDto = (
  layout: ReportDashboardSectionPositionWithId[],
): DashboardLayoutDto => {
  return layout.map(({ i, type, ...position }) => ({
    id: i,
    position,
    section_type: type === 'divider' ? type : 'widget',
  })) as DashboardLayoutDto;
};
