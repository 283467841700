import {
  ColumSizeWidgetState,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  PeriodTypeWidgetState,
  WidgetStateColumns,
  WidgetStatePeriod,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useKpiWidgetColumnVisibilityState } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useColumnsVisibilityState';
import { useWidgetTableColumnSize } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useWidgetTableColumnSize';
import { useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { ThinTabGroup } from '@/stories';
import { AgGridReact } from 'ag-grid-react';
import { KpiTableWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { WidgetStateReckonerPeriodType } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStatePeriodType';
import {
  addHideHeadersToVizConfig,
  KpiTableGroupingType,
  KpiTableWidgetConfig,
  KpiTableWidgetSection,
  useKpiTableWidgetExportFeature,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableWidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/ui/KpiTableWidgetTable';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { useRef } from 'react';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';

const GROUPING_TYPES = [
  'assets',
  'segments',
] as const satisfies readonly KpiTableGroupingType[];

export type KpiTableWidgetState = DateWidgetState &
  ColumSizeWidgetState &
  DisplayedGroupsWidgetState & {
    groupingType: KpiTableGroupingType;
  } & PeriodTypeWidgetState;

export function KpiTableWidget(
  props: WidgetProps<KpiTableWidgetDto, KpiTableWidgetSection> &
    WidgetStateProps<KpiTableWidgetState> &
    WidgetConfigProps<KpiTableWidgetConfig> &
    WidgetContextProps<DisplayedGroupsWidgetContext>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    settings,
    onSettingsChange,
    isLoading,
    isError,
    isFetching,
    context,
    mode,
  } = props;

  const gridRef = useRef<AgGridReact | null>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const exportFeature = useKpiTableWidgetExportFeature(
    {
      mode,
      gridRef,
    },
    {
      widgetTitle: widgetSection.title,
    },
  );

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const columnVisibilityFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const { default_options } = widgetSection.widgetConfig;

  return (
    <DashboardWidgetTableCard
      {...props}
      ref={wrapperDivRef}
      className={mode === 'edit' ? 'h-[500px]' : 'h-full'}
    >
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>
        <div className="grow" />
        {!widgetStateFullScreenFeature.isFullscreen &&
          context.columnVisibilityEnabled && (
            <WidgetStateColumns
              onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
              state={state}
              groupsState={{
                groups: state.displayedGroups ?? [],
              }}
              onColumnStateChange={columnVisibilityFeature.onChange}
            />
          )}
        <div className="flex gap-tw-2">
          <ThinTabGroup
            selectedItem={state.groupingType}
            onSelectedItemChange={(tab) =>
              onStateChange({
                ...state,
                groupingType: tab.id as KpiTableGroupingType,
              })
            }
            items={GROUPING_TYPES.map((type) => ({
              id: type,
              label: capitalize(type),
            }))}
          />
        </div>
        {mode !== 'pdf' && <widgetStateFullScreenFeature.IconButton />}
      </DashboardWidgetCard.Header>
      <DashboardWidgetCard.Panel>
        {default_options.period_type === 'week' ? (
          // move week to WidgetStatePeriod
          <Calendar
            selectionMode="weekly"
            value={[
              dayjs(state.date).startOf('w'),
              dayjs(state.date).endOf('w'),
            ]}
            onChange={(date) => {
              onStateChange({
                ...state,
                date: formatToDateStringForRequest(date[0]),
              });
            }}
          />
        ) : (
          <WidgetStatePeriod state={state} onStateChange={onStateChange} />
        )}
        {default_options.period_type !== 'week' && (
          <WidgetStateReckonerPeriodType
            state={state}
            onStateChange={onStateChange}
            periodTypes={
              widgetSection.widgetConfig?.period_types ??
              widgetSection.defaultOptions?.availablePeriodTypes ??
              []
            }
          />
        )}
        <div className="grow" />

        <exportFeature.ExportButtonComponent />
      </DashboardWidgetCard.Panel>
      {!isLoading && !isError && (
        <KpiTableWidgetTable
          ref={gridRef}
          vizConfig={addHideHeadersToVizConfig(
            widgetSection.widgetConfig.viz_config,
          )}
          data={data}
          state={state}
          onStateChange={onStateChange}
          settings={settings}
          onSettingsChange={onSettingsChange}
          mode={mode}
          columnsConfig={widgetSection.widgetConfig.columns}
          onRowDataChanged={columnSizeFeature.handleRowDataChanged}
          excelStyles={exportFeature.excelStyles}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
