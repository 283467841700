import React, { useCallback, useState } from 'react';
import { Button, Icon, Popover } from '@/stories';
import {
  CalendarRange,
  CalendarRangeProps,
  CalendarRangeRef,
  DateRangeType,
} from 'stories/Calendar/Range/CalendarRange';
import { formatDate } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';
import { DateRangeInput } from 'stories/DateRangeInput/DateRangeInput';
import dayjs from 'dayjs';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE } from 'stories/Calendar/config';

const StepIcon = ({
  direction,
  triggered,
  ...props
}: {
  direction: 'next' | 'prev';
  triggered?: boolean;
} & Omit<React.ComponentProps<typeof Icon>, 'iconName'>) => (
  <Icon
    data-testid={`step-${direction}`}
    className={cn(
      'group:hover:text-neutral-650 flex h-full items-center overflow-hidden px-tw-2 text-neutral-550 hover:bg-neutral-050 hover:text-neutral-650',
      triggered && 'text-neutral-650',
    )}
    iconName={direction === 'next' ? 'arrowRight' : 'arrowLeft'}
    {...props}
  />
);
const Separator = () => <div className="h-[24px] w-[1px] bg-neutral-100" />;

interface Props extends CalendarRangeProps {
  /** The selected date range. */
  value?: DateRangeType | undefined;

  /** If true, the step (previous/next) buttons are disabled.
   *  Step buttons are used to navigate through the date range.
   * */
  disableStepButtons?: boolean;

  /** The default date range.
   *  If provided, a button 'Back To Default' will be displayed to reset the date range to the default value.
   * */
  defaultValue?: DateRangeType;

  /** If true, the date picker will not close when a date is selected. */
  disableCloseOnSelect?: boolean;

  /** The step value for the step buttons. */
  stepValue?: number;

  /** The unit for the step value ('day', 'month', 'year', or 'week'). */
  stepUnit?: 'day' | 'month' | 'year' | 'week';
}

export function CalendarRangeSelector({
  value,
  onChange,
  disableStepButtons,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  stepValue = 1,
  stepUnit = 'day',
  ...props
}: Props): React.ReactNode {
  const calendarRangeRef = React.useRef<CalendarRangeRef>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const handleStepClick = useCallback(
    (direction: 'next' | 'prev') => (e: React.MouseEvent) => {
      e.stopPropagation();
      const [from, to] = value ?? [];
      const stepSign = direction === 'next' ? 1 : -1;
      const step = stepValue * stepSign;
      const newValue = [
        dayjs(from).add(step, stepUnit).toDate(),
        dayjs(to).add(step, stepUnit).toDate(),
      ];
      onChange?.(newValue as DateRangeType);
    },
    [value, onChange, stepValue, stepUnit],
  );

  const toggleCalendar = useCallback(() => {
    setPopoverVisible((prev) => !prev);
    calendarRangeRef.current?.onClose();
  }, []);

  const handleChange = useCallback(
    (newValue: DateRangeType) => {
      onChange?.(newValue);
      if (!props.disableCloseOnSelect) {
        toggleCalendar();
      }
    },
    [onChange],
  );

  const handleBackToDefault = useCallback(() => {
    onChange?.(props.defaultValue!);
    if (!props.disableCloseOnSelect) {
      toggleCalendar();
    }
  }, [props.defaultValue]);

  return (
    <Popover
      visible={popoverVisible}
      appendToBody={false}
      placement="bottom-start"
      maxWidth="min-content"
      hiddenArrow
      className="overflow-hidden rounded-2xl p-0 shadow-m"
      onClickOutside={toggleCalendar}
      template={
        <div className="flex">
          <div className="flex w-[164px] grow flex-col items-start justify-between border-r border-solid border-neutral-150 p-tw-4">
            <DateRangeInput
              value={value}
              onChange={onChange}
              minDate={minDate}
              maxDate={maxDate}
              {...props}
            />
            {props.defaultValue && (
              <Button
                size="xs"
                className="whitespace-nowrap"
                onClick={handleBackToDefault}
                variant="secondary"
              >
                Back To Default
              </Button>
            )}
          </div>
          <CalendarRange
            ref={calendarRangeRef}
            value={value}
            onChange={handleChange}
            {...props}
          />
        </div>
      }
    >
      {({ triggered }) => (
        <Button
          size="s"
          className={cn(
            'group inline-semibold gap-0 p-0',
            triggered && ' bg-neutral-200',
          )}
          variant="secondary"
          onClick={toggleCalendar}
        >
          {!disableStepButtons && (
            <>
              <StepIcon
                onClick={handleStepClick('prev')}
                triggered={triggered}
                direction="prev"
              />
              <Separator />
            </>
          )}
          {value?.[0] && value[1] ? (
            <div className="flex gap-tw-1 px-tw-2">
              <span className="text-neutral-550">From:</span>
              {formatDate(value[0], 'MM-DD-YYYY')}
              <span className="text-neutral-550">To:</span>
              {formatDate(value[1], 'MM-DD-YYYY')}
            </div>
          ) : (
            'Select Date Range'
          )}
          {!disableStepButtons && (
            <>
              <Separator />
              <StepIcon
                onClick={handleStepClick('next')}
                triggered={triggered}
                direction="next"
              />
            </>
          )}
        </Button>
      )}
    </Popover>
  );
}
