import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCoreAssetsByAssetIdReportBuilderReports: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports`,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReports: build.mutation<
      PostApiCoreAssetsByAssetIdReportBuilderReportsApiResponse,
      PostApiCoreAssetsByAssetIdReportBuilderReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
      }),
    }),
    putApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.mutation<
      PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.mutation<
      DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdf: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}/preview_pdf`,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublish: build.mutation<
      PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
      PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}/publish`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
      build.query<
        PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
        PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.reportId}/widgets/${queryArg.id}/snapshot`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
      build.mutation<
        PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
        PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.reportId}/widgets/${queryArg.id}/snapshot`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreAssetsReportBuilderReportsGeneratedApi };
export type GetApiCoreAssetsByAssetIdReportBuilderReportsApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto[];
export type GetApiCoreAssetsByAssetIdReportBuilderReportsApiArg = {
  assetId: string;
};
export type PostApiCoreAssetsByAssetIdReportBuilderReportsApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PostApiCoreAssetsByAssetIdReportBuilderReportsApiArg = {
  assetId: string;
  body: {
    name: string;
    date: string;
    template_id: string;
    subtitle: string;
  };
};
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  /** status 200 success */ {
    report: AssetsReportBuilderReportDto;
    meta: {
      asset: AssetsReportBuilderAssetDto;
    };
  };
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
};
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
  body: {
    name?: string;
    subtitle?: string;
    date?: string;
    legal_entity_ids?: string[];
  };
};
export type DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  unknown;
export type DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
};
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiResponse =
  /** status 200 success */ string;
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiArg =
  {
    id: string;
    assetId: string;
  };
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiArg = {
  id: string;
  assetId: string;
  body: {
    shared_file: {
      public_permission_attributes?: {
        _destroy?: boolean;
      };
      directly_permitted_user_role_ids: string[];
      directly_permitted_user_ids: string[];
      directly_permitted_investment_entity_ids: string[];
      directly_permitted_user_tag_ids: string[];
    };
  };
};
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | AssetsReportBuilderKpiCardWidgetDto
      | AssetsReportBuilderTextAreaWidgetDto
      | AssetsReportBuilderMediaWidgetDto
      | AssetsReportBuilderUnitMixTableWidgetDto
      | AssetsReportBuilderUnitMixSummaryTableWidgetDto
      | AssetsReportBuilderFinancialTableSingleDateWidgetDto
      | AssetsReportBuilderXyChartWidgetDto
      | AssetsReportBuilderRecentRentRollTableWidgetDto
      | AssetsReportBuilderDelinquencyTrackerTableWidgetDto
      | AssetsReportBuilderHistoricalReviewTableWidgetDto;
  };
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg =
  {
    reportId: string;
    assetId: string;
    id: string;
    body: {
      context?: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
      };
    };
  };
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | AssetsReportBuilderTextAreaWidgetDto
      | AssetsReportBuilderMediaWidgetDto;
  };
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg =
  {
    reportId: string;
    assetId: string;
    id: string;
    body: {
      snapshot_data:
        | {
            text: string;
          }
        | {
            items: {
              id: string;
              type: 'shared_file';
            }[];
          };
    };
  };
export type AssetsReportBuilderSharedFileDto = {
  id: string;
  permitted?: {
    isPublic: boolean;
    users: {
      id: string;
      fullName: string;
      email: string;
    }[];
    roles: {
      id: string;
      name: string;
    }[];
    tags: {
      id: string;
      name: string;
    }[];
    investmentEntities: {
      id: string;
      name: string;
    }[];
  };
};
export type AssetsReportBuilderPreviewReportDto = {
  id: string;
  name: string;
  subtitle: string;
  status: 'draft' | 'published';
  date: string;
  confidential?: boolean;
  sharedFile: AssetsReportBuilderSharedFileDto | null;
};
export type UnprocessableEntityDto = {
  errors?: string[];
};
export type AssetsReportBuilderLegalEntityDto = {
  id: string;
  name: string;
  code: string;
};
export type AssetsReportBuilderReportWidgetDto = {
  id: string;
  title: string | null;
  widgetType:
    | 'kpi_card'
    | 'text_area'
    | 'unit_mix_table'
    | 'financial_table_single_date'
    | 'unit_mix_summary_table'
    | 'historical_review_table'
    | 'delinquency_tracker_table'
    | 'xy_chart';
  position: {
    y: number;
    w: number;
  };
  fontSize: number | null;
  snapshotContext: {
    date: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    granularity: string | null;
  } | null;
  defaultOptions: {
    dateFrom: string | null;
    dateTo: string | null;
    granularity: string | null;
  } | null;
};
export type AssetsReportBuilderReportGroupDto = {
  id: string;
  position: number;
  pageOrientation: 'portrait' | 'landscape';
  widgets: AssetsReportBuilderReportWidgetDto[];
};
export type AssetsReportBuilderReportDto = {
  id: string;
  name: string;
  subtitle: string;
  status: 'draft' | 'published';
  date: string;
  legalEntities: AssetsReportBuilderLegalEntityDto[];
  groups: AssetsReportBuilderReportGroupDto[];
};
export type AssetsReportBuilderAssetDto = {
  id: number;
  name: string;
  slug?: string;
  address?: string;
  legalEntities: AssetsReportBuilderLegalEntityDto[];
};
export type ValueDisplayOptionsDto = {
  type: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
};
export type AssetsReportBuilderKpiCardWidgetDto = {
  widgetType: 'kpi_card';
  kpis: {
    key: number;
    label: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
    value: number | null;
  }[];
};
export type AssetsReportBuilderTextAreaWidgetDto = {
  widgetType: 'text_area';
  text: string;
};
export type AssetsReportBuilderMediaWidgetDto = {
  widgetType: 'media';
  items: {
    id: string;
    type: 'shared_file';
    dataUri: string;
  }[];
};
export type AssetsReportBuilderUnitMixTableWidgetDto = {
  widgetType: 'unit_mix_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type AssetsReportBuilderUnitMixSummaryTableWidgetDto = {
  widgetType: 'unit_mix_summary_table';
  headerBackground: string | null;
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  };
};
export type ValueDisplayOptionsConfigDto = {
  type: 'number' | 'percentage' | 'currency' | 'metric' | 'sqft' | 'bps';
  precision: 0 | 1 | 2;
  kilo_formatting: boolean;
  hide_comma_separator?: boolean;
};
export type VizConfigBackgroundDto = {
  background?:
    | {
        type: 'gradient';
        ignore_zeros?: boolean;
        thresholds: {
          type: 'min' | 'max' | 'number' | 'percentage' | 'percentile';
          value?: number;
        }[];
      }
    | {
        type: 'color';
        color: string;
      };
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string;
    value: number | null;
  }[];
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetDto = {
  widgetType: 'financial_table_single_date';
  hideHeader: boolean;
  headerBackground: string | null;
  agGridConfig: object;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'total';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'blank';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'formula';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'category';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type AssetsReportBuilderXyChartWidgetDto = {
  widgetType: 'xy_chart';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type AssetsReportBuilderRecentRentRollTableWidgetDto = {
  widgetType: 'recent_rent_roll_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    label: string;
    path: string;
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    path: string;
  };
  psf: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
    path: string;
  };
  latestParsings?: {
    legalEntityId: string;
    date: string;
  }[];
};
export type AssetsReportBuilderDelinquencyTrackerTableWidgetDto = {
  widgetType: 'delinquency_tracker_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type AssetsReportBuilderHistoricalReviewTableWidgetDto = {
  widgetType: 'historical_review_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: object[];
};
