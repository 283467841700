import React, { useState } from 'react';
import {
  GranularityField,
  PeriodShiftField,
  UpdateWidgetTitle,
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import Button from 'stories/Button/Button';
import { DragDropContext } from '@hello-pangea/dnd';
import { Field, IconButton, ThinTabGroup } from '@/stories';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { DefaultDateRangeField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/DefaultDateRangeField';
import { useTabs } from 'stories/Tabs/useTabs';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { XYChartSingleKpiWidgetConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/row';
import {
  DEFAULT_XY_CHART_SINGLE_KPI_WIDGET_CONFIG,
  transformXYChartSingleKpiWidgetConfigToForm,
  X_AXIS_OPTIONS,
  XYChartSingleKpiWidgetConfigForm,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/component.form';
import { RadioGroupController } from '@/stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { XYChartWidgetConfigKpi } from '../model';
import { GroupingTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { updateDefaultOptions } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/updaters';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'data',
    label: 'Data',
  },
] as const satisfies readonly IThinTabItem[];
const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI
    >();

  const methods = useForm<XYChartSingleKpiWidgetConfigForm>({
    defaultValues: {
      ...DEFAULT_XY_CHART_SINGLE_KPI_WIDGET_CONFIG,
      ...transformXYChartSingleKpiWidgetConfigToForm(widget.widgetConfig),
    },
  });
  const { control, watch } = methods;

  useUpdateWidgetConfigEffect<
    XYChartSingleKpiWidgetConfigForm,
    typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI
  >(methods, updateDefaultOptions);
  const defaultDateRange = watch('defaultDateRange');
  const xAxis = watch('xAxis');

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Field labelText="Period Shift">
          <PeriodShiftField hideKey control={control} name="periodShiftTo" />
        </Field>
        <Field labelText="X Axis">
          <RadioGroupController
            options={mapItemsToListOption(X_AXIS_OPTIONS)}
            control={control}
            name="xAxis"
          />
        </Field>
        {xAxis === 'date' && (
          <>
            <Field labelText="Group By">
              <GranularityField control={control} name="granularity" />
            </Field>
            <Field labelText="Default Range">
              <DefaultDateRangeField
                periodShiftName="periodShiftFrom"
                control={control}
                name="defaultDateRange"
              />
            </Field>
          </>
        )}
        {defaultDateRange === 'none' && (
          <Field labelText="Period Shift (Date From)">
            <PeriodShiftField control={control} name="periodShiftFrom" />
          </Field>
        )}
        <GroupingTypeField control={control} name="groupingType" />
      </FieldsContainer>
    </FormProvider>
  );
};
const DataTab = () => {
  const [modalState, setModalState] = useState<
    'create' | XYChartWidgetConfigKpi | null
  >(null);
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI
    >();
  const { kpis = [] } = widget.widgetConfig;
  const {
    removeItem: removeRow,
    moveItem: moveRow,
    cloneItem: cloneRow,
  } = useUpdateListPropertyWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI,
    'kpis',
  );
  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        label={kpis.length === 0 ? 'No Items Yet' : `${kpis.length} ITEMS`}
      >
        <Button
          onClick={() => {
            setModalState('create');
          }}
          size="xs"
          variant="secondary"
        >
          Add
        </Button>
      </WidgetSettingsListLayout.Header>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (!destination) return;
          moveRow(source.index, destination.index);
        }}
      >
        <WidgetSettingsListLayout.SortableList>
          {kpis.map((row, index) => (
            <WidgetSettingsListLayout.SortableListItem
              index={index}
              draggableId={row.key.toString()}
              key={row.key}
              listIndex={index}
              className="group/item"
            >
              <WidgetSettingsListLayout.ListItem.Text>
                {row.label}
              </WidgetSettingsListLayout.ListItem.Text>
              <div className="grow" />
              <WidgetSettingsListLayout.ListItem.ActionsPanel>
                <IconButton
                  iconName="copy"
                  onClick={() => {
                    cloneRow(row.key);
                  }}
                />
                {isFormulaByExpression(row.expression) && (
                  <InspectFormulaButton reference={row.expression} />
                )}
                <IconButton
                  onClick={() => {
                    setModalState(row);
                  }}
                  iconName="edit"
                />
                <IconButton
                  onClick={() => {
                    removeRow(row.key);
                  }}
                  iconName="trash"
                />
              </WidgetSettingsListLayout.ListItem.ActionsPanel>
            </WidgetSettingsListLayout.SortableListItem>
          ))}
        </WidgetSettingsListLayout.SortableList>
      </DragDropContext>
      {modalState != null && (
        <XYChartSingleKpiWidgetConfigRow
          type={modalState === 'create' ? 'create' : 'edit'}
          rowConfig={modalState === 'create' ? null : modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState('create');
              });
            }
          }}
        />
      )}
    </WidgetSettingsListLayout>
  );
};

export function EagleEyeDashboardXYChartWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);

  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'data' && <DataTab />}
      </div>
    </div>
  );
}
