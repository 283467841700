import {
  DEFAULT_LINE_SERIES_TEMPLATE,
  DEFAULT_SERIES_REF_KEY,
  DEFAULT_TEMPLATE_SERIES_REF_KEY,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/amchartDefaults';
import { produce } from 'immer';
import {
  OBJECT_DASHBOARD_SECTION_TYPE,
  WidgetDateGranularity,
  XyChartSingleKpiWidgetDto,
} from 'bundles/Shared/entities/dashboard';
import {
  listUpdater,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  XYChartWidgetConfig,
  XYChartWidgetConfigKpi,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { Root } from '@amcharts/amcharts5';
import {
  DEFAULT_AMCHART_DATE_FORMATS,
  formatVariableString,
  getAmchartNumberFormatForByDisplayOptions,
} from '@/shared/lib/formatting/charts';
import { MutableRefObject, RefObject } from 'react';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { sanitizeFileName } from 'lib/uploadFiles';
import { ColumnSeries, XYChart, XYSeries } from '@amcharts/amcharts5/xy';
import { IExportingSettings } from '@amcharts/amcharts5/.internal/plugins/exporting/Exporting';
import { UnknownRecord } from 'type-fest/source/internal';
import { clone, groupBy, orderBy } from 'lodash-es';
import { getColumnExcelFormat } from '@/shared/lib/formatting/excel';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { XYChartWidgetConfigRowForm } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/row.form';
import { XYChartSingleKpiWidgetState } from './widget';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import * as am5 from '@amcharts/amcharts5';
import { Settings } from '@amcharts/amcharts5/.internal/core/util/Entity';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';

type Series = {
  settings: {
    name: string;
    valueYField: string;
    tooltip: {
      settings: {
        labelText: string;
      };
    };
    fill: {
      type: string;
      value: string;
    };
    stroke: {
      type: string;
      value: string;
    };
  };
};

export type AmChartConfig = {
  refs: Record<string, Series[]>[];
};

export const isChartCategorical = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.some(
    (ref) => 'xAxis' in ref && ref.xAxis.type === 'CategoryAxis',
  );
};
export const hasSeriesRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.some((ref) => DEFAULT_SERIES_REF_KEY in ref);
};

export const findSeriesByKpiKey = (seriesArr: Series[], kpiKey: number) => {
  return seriesArr.find(
    (series) => series.settings.valueYField === kpiKey.toString(),
  );
};

export const findSeriesRefInConfig = (
  amchartConfig: AmChartConfig,
  seriesKey = DEFAULT_SERIES_REF_KEY,
) => {
  return amchartConfig.refs.find((ref) => seriesKey in ref);
};

const getAmchartJsonColorFromString = (color: string) => {
  return {
    type: 'Color',
    value: color,
  };
};

export const removeKpiFromConfig = (
  config: XYChartWidgetConfig,
  kpiKey: number,
) => {
  const { removeItem: removeRow } = listUpdater(config.kpis, {
    key: 'key',
  });

  // eslint-disable-next-line no-param-reassign
  config.kpis = removeRow(kpiKey);
};

export const useRemoveKpiFromConfig = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART>();
  const [updateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART,
  );

  return (kpiKey: number) => {
    updateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        removeKpiFromConfig(draft, kpiKey);
      }),
    });
  };
};

export const upsertSeriesInConfig = (
  amchartConfig: AmChartConfig,
  seriesConfig: XYChartWidgetConfigRowForm,
) => {
  const seriesRef = findSeriesRefInConfig(amchartConfig);
  if (!seriesRef) {
    return;
  }

  const series = findSeriesByKpiKey(
    seriesRef[DEFAULT_SERIES_REF_KEY],
    seriesConfig.key,
  );

  const updatedSeries = produce(
    series ?? DEFAULT_LINE_SERIES_TEMPLATE,
    (draft) => {
      draft.settings.name = seriesConfig.label;
      draft.settings.valueYField = seriesConfig.key.toString();
      draft.settings.tooltip.settings.labelText = formatVariableString(
        'valueY',
        getAmchartNumberFormatForByDisplayOptions(
          seriesConfig.value_display_options,
        ),
      );
      if (seriesConfig.color) {
        draft.settings.fill = getAmchartJsonColorFromString(seriesConfig.color);
        draft.settings.stroke = getAmchartJsonColorFromString(
          seriesConfig.color,
        );
      }
    },
  );

  if (series) {
    const seriesIndex = seriesRef[DEFAULT_SERIES_REF_KEY].indexOf(series);
    seriesRef[DEFAULT_SERIES_REF_KEY][seriesIndex] = updatedSeries;
  } else {
    seriesRef[DEFAULT_SERIES_REF_KEY].push(updatedSeries);
  }
};
export const TOTAL_SERIES_NAME = 'Total';
export const AVERAGE_SERIES_NAME = 'Average';
export const KPI_GOAL_SERIES_NAME = 'Target';

export const buildDataFieldsFromSeries = (series: XYSeries[]) => {
  return Object.fromEntries(
    series.map((s) => {
      return [s.get('valueYField')!, s.get('name')!];
    }),
  );
};

export const DEFAULT_AMCHART_EXCEL_DATE_FIELD = 'dateFrom';
export const DEFAULT_AMCHART_EXCEL_DATE_LABEL = 'Date';
export const addExportToChart = ({
  chartRef,
  root,
  title,
  data,
  granularity,
  dataFields,
  withoutDate,
}: {
  chartRef: MutableRefObject<
    | (XYChart & {
        exporting?: am5plugins_exporting.Exporting;
      })
    | null
  >;
  root: Root;
  dataFields: Record<string, string>;
  title: string;
  data: unknown[];
  withoutDate?: boolean;
  granularity?: WidgetDateGranularity;
}) => {
  const config: IExportingSettings = {
    filePrefix: sanitizeFileName(title),
    dataSource: data,
    dataFields,
    dataFieldsOrder: Object.keys(dataFields),
    numericFields: Object.keys(dataFields),
  };
  if (!withoutDate) {
    config.dateFields = [DEFAULT_AMCHART_EXCEL_DATE_FIELD];
    config.dataFields!.dateFrom = DEFAULT_AMCHART_EXCEL_DATE_LABEL;
    config.dataFieldsOrder!.unshift(DEFAULT_AMCHART_EXCEL_DATE_FIELD);
    config.dateFormat =
      granularity && DEFAULT_AMCHART_DATE_FORMATS[granularity];
  }
  // eslint-disable-next-line no-param-reassign
  chartRef.current!.exporting = am5plugins_exporting.Exporting.new(
    root,
    config,
  );

  return config;
};

export const sanitizeCells = (sheet: UnknownRecord): string[] => {
  // result cells in excel sheet has extra ref key
  return Object.keys(sheet).filter((k) => k !== '!ref');
};

export const getColumnLetterFromCellKey = (cellKey: string) => {
  return cellKey.at(0);
};

export const filterDateColumn = (data: Record<string, unknown>) => {
  const dataClone = clone(data);
  const cellKey = Object.entries(data).find(([_, value]) => {
    return value.v === DEFAULT_AMCHART_EXCEL_DATE_LABEL;
  })?.[0];
  if (!cellKey) {
    return data;
  }
  // eslint-disable-next-line prefer-destructuring
  const columnLetter = getColumnLetterFromCellKey(cellKey);
  Object.keys(dataClone).forEach((key) => {
    if (key.includes(columnLetter)) {
      delete dataClone[key];
    }
  });
  return dataClone;
};

export const getFirstSheetFromWorkbook = (workbook: UnknownRecord) => {
  return workbook.Sheets.Data;
};

export const applyFormatToCell = (
  cell: UnknownRecord,
  format: ValueDisplayOptions,
) => {
  // eslint-disable-next-line no-param-reassign
  cell.z = getColumnExcelFormat(format);
};

export const addExportToXySingleKpiChart = ({
  state,
  ref,
  widgetTitle,
  items,
  seriesNames,
  selectedKpi,
  root,
}: {
  state: XYChartSingleKpiWidgetState;
  ref: RefObject<XYChart & { exporting?: am5plugins_exporting.Exporting }>;
  widgetTitle: string;
  items: UnknownRecord[];
  seriesNames: string[];
  root: Root;
  selectedKpi?: XYChartWidgetConfigKpi;
}) => {
  const dataSource = Object.entries(
    groupBy(items, DEFAULT_AMCHART_EXCEL_DATE_FIELD),
  ).map(([dateFrom, values]) => {
    const objectEntries = Object.fromEntries(
      values.map((value) => [value.objectName, value.kpiValue]),
    );
    return {
      dateFrom: Number(dateFrom),
      ...objectEntries,
    };
  });
  // eslint-disable-next-line no-param-reassign
  ref.current!.exporting = am5plugins_exporting.Exporting.new(root, {
    filePrefix: sanitizeFileName(widgetTitle),
    dataSource,
    dateFields: [DEFAULT_AMCHART_EXCEL_DATE_FIELD],
    dateFormat: DEFAULT_AMCHART_DATE_FORMATS[state.granularity],
    numericFields: seriesNames,
    dataFields: {
      [DEFAULT_AMCHART_EXCEL_DATE_FIELD]: DEFAULT_AMCHART_EXCEL_DATE_LABEL,
      ...Object.fromEntries(
        seriesNames.map((seriesName) => [seriesName, seriesName]),
      ),
    },
  });

  ref.current!.exporting.events.on('workbookready', function (event) {
    if (selectedKpi?.value_display_options == null) {
      return;
    }
    const dataSheet = getFirstSheetFromWorkbook(event.workbook);
    const cellKeys = sanitizeCells(dataSheet);
    cellKeys.forEach((cellKey) => {
      // eslint-disable-next-line no-param-reassign
      applyFormatToCell(dataSheet[cellKey], selectedKpi?.value_display_options);
    });
  });
};
export const TAB_ITEMS = [
  {
    label: 'Assets',
    id: 'assets',
  },
  {
    label: 'Segments',
    id: 'segments',
  },
  {
    label: 'Mixed',
    id: 'mixed',
  },
] as const satisfies IThinTabItem[];
export type GroupingType = (typeof TAB_ITEMS)[number]['id'];
export type ObjectType = 'asset' | 'segment';
export const GROUPING_TYPE_TO_OBJECT_TYPE: Record<
  Exclude<GroupingType, 'mixed'>,
  ObjectType
> = {
  assets: 'asset',
  segments: 'segment',
};

export const idToObjectMapper = (type: ObjectType) => (id: number) => ({
  id,
  type,
});

export const ID_DELIMITER = '-';
export const buildObjectId = ({
  type,
  id,
}: {
  type: 'asset' | 'segment';
  id: number | string;
}) => {
  return `${type}${ID_DELIMITER}${id}`;
};
export const parseObjectId = (stringId: string) => {
  const [type, id] = stringId.split(ID_DELIMITER);
  return { type, id: Number(id) };
};
export const isAssetId = (stringId: string) => {
  return stringId.startsWith(`asset${ID_DELIMITER}`);
};
export const isSegmentId = (stringId: string) => {
  return stringId.startsWith(`segment${ID_DELIMITER}`);
};

export const addAdaptersToColumnSeries = (columnSeries: ColumnSeries) => {
  const adapters = columnSeries.columns.template._settings.adapters as {
    property: string;
    condition: {
      key: string;
      value: string;
    };
    value: string;
  }[];
  if (adapters == null || adapters.length === 0) {
    return;
  }
  adapters.forEach((adapter) => {
    const adapterHandler = (value, target) => {
      const conditionValue = target.dataItem?.get(adapter.condition.key);
      if (conditionValue && adapter.condition.value.includes(conditionValue)) {
        return am5.color(adapter.value);
      }
      return value;
    };
    columnSeries.columns.each((c) =>
      c.adapters.add(adapter.property as 'fill', adapterHandler),
    );
  });
  const labelYPositionAdapter = function (centerY: number, target: Settings) {
    const { dataItem } = target;
    if (dataItem) {
      const value = dataItem.get('valueY', 0);
      if (value < 0) {
        return 0;
      }
    }

    return centerY;
  };
  columnSeries.bulletsContainer.children.each((l) => {
    l.adapters.add('centerY', labelYPositionAdapter);
  });
};

export const orderItems = (items: XyChartSingleKpiWidgetDto['data']) => {
  if (items.length === 0) {
    return items;
  }
  const orderedByKpiValue = orderBy(
    items,
    ['kpiKey', 'kpiValue'],
    ['asc', 'desc'],
  );
  const firstKpiKey = orderedByKpiValue[0].kpiKey;
  const objectNamesOrder = orderedByKpiValue
    .filter((o) => o.kpiKey === firstKpiKey)
    .map((o) => o.objectName);
  const getOrderIndex = (objectName: string) => {
    const index = objectNamesOrder.indexOf(objectName);
    return index === -1 ? objectNamesOrder.length : index; // If not found, place it at the end
  };
  const orderedItems = orderBy(
    items,
    ['kpiKey', (item) => getOrderIndex(item.objectName)],
    ['asc', 'asc'],
  );

  return orderedItems;
};

export const findTemplateSeriesRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.find(
    (ref) => DEFAULT_TEMPLATE_SERIES_REF_KEY in ref,
  );
};

export const findXAxisRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.find((ref) => 'xAxis' in ref);
};

export const sleepUntilSeriesAreReady = async () => {
  await sleep(100);
};

export const DEFAULT_GRID_INVERVALS = [
  { timeUnit: 'day', count: 1 },
  { timeUnit: 'day', count: 2 },
  { timeUnit: 'day', count: 3 },
  { timeUnit: 'day', count: 4 },
  { timeUnit: 'day', count: 5 },
  { timeUnit: 'day', count: 20 },
  { timeUnit: 'week', count: 1 },
  { timeUnit: 'week', count: 12 },
  { timeUnit: 'month', count: 1 },
  { timeUnit: 'month', count: 2 },
  { timeUnit: 'month', count: 3 },
  { timeUnit: 'month', count: 6 },
] as const;
