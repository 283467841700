import { CheckboxWithRefreshButton } from '@/bundles/REport/components/financials/CardList';
import { OBJECTABLE_TYPE_COLOR_MAP } from '@/bundles/REport/components/financials/consts';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { ComparisonDashboardLayout } from '@/bundles/Shared/entities/comparison';
import {
  buildLayoutsId,
  DashboardContext,
  DashboardContextValue,
  ReportDashboardType,
  useGetApiReportComparisonDashboardsByIdQuery,
} from '@/bundles/Shared/entities/dashboard';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { useFavoriteComparisonDashboardAssets } from '@/bundles/Shared/features/comparison';
import { useNavigateToInitialStateComparisonDashboardEffect } from '@/bundles/Shared/features/comparison/lib/useNavigateToInitialStateComparisonDashboardEffect';
import { DashboardBoards } from '@/bundles/Shared/widgets/dashboard/board/ui/DashboardBoards';
import { DEFAULT_LAYOUT_PROPS } from '@/bundles/Shared/widgets/dashboard/layout';
import { ComparisonDashboardWidgetStateIntersection } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { FinancialTableSingeDateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
  TRouteQueryParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { ResponsiveGridLayout } from '@/shared/lib/react-grid-layout/ResponsiveGridLayout';
import {
  AnimationLoader,
  Button,
  Icon,
  IconButton,
  ProjectCard,
  SearchInput,
} from '@/stories';
import FavoriteIconButton from '@/stories/ProjectCard/FavoriteIconButton';
import ProjectCardList from '@/stories/ProjectCard/ProjectCardList';
import { IAsset } from '@/types/Asset';
import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import ListLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { isEqual, orderBy, sortBy, xor } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { DashboardSettingsPageToggle } from '@/pages/report/dashboards/ui/DashboardSettingsPageToggle';
import { ComparisonDashboardWidget } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ComparisonDashboardWidget';

const DashboardWidget = ({
  assetIds,
  allAssets,
  widgetSection: widget,
  legalEntityIds: allLegalEntityIds,
}: {
  widgetSection: ReportComparisonDashboardSection;
  assetIds: IAsset['id'][];
  allAssets: Pick<IAsset, 'id' | 'legalEntities' | 'name'>[];
  legalEntityIds: LegalEntity['id'][];
}) => {
  const { dashboardId } =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.report.comparisonDashboards.fullPath>
    >();
  const { boardId = '' } =
    useQueryParams<
      TRouteQueryParams[typeof ROUTES_ROOT.report.comparisonDashboards.fullPath]
    >();
  const reportComparisonDashboardsState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  )!;

  const state = reportComparisonDashboardsState.widgetsState[
    widget.id
  ] as unknown as ComparisonDashboardWidgetStateIntersection;

  const legalEntityIds = xor(
    allLegalEntityIds,
    state.excludedLegalEntityIds ?? [],
  );

  const contextAssets = useMemo(() => {
    const allAssetsMap = new Map(allAssets.map((a) => [a.id, a]));

    return assetIds
      .map((assetId) => {
        return allAssetsMap.get(Number(assetId));
      })
      .filter(Boolean);
  }, [allAssets, assetIds]);

  return (
    <ComparisonDashboardWidget
      mode="view"
      dashboardId={dashboardId}
      widgetSection={widget}
      boardId={boardId}
      legalEntityIds={legalEntityIds}
      context={
        {
          columnVisibilityEnabled: true,
          assets: contextAssets as IAsset[],
        } satisfies FinancialTableSingeDateWidgetContext
      }
    />
  );
};

const ExplicitSuspense = ({
  children,
  fallback,
  isLoading,
}: PropsWithChildren & {
  isLoading: boolean;
  fallback: React.ReactNode;
}) => {
  if (isLoading) return fallback;
  return children;
};

export type ReportComparisonDashboardObject = Pick<
  IAsset,
  'id' | 'name' | 'legalEntities' | 'pictureUrl' | 'slug'
> & { _type: 'asset'; _dashboardId: string };

export const ComparisonModePage: FC<RouteComponentProps> = () => {
  const { isItemFavorite, toggleItemFavorite } =
    useFavoriteComparisonDashboardAssets();
  const { value: fullscreen, toggle: toggleFullscreen } = useBoolean();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const { boardId = '' } =
    useQueryParams<
      TRouteQueryParams[typeof ROUTES_ROOT.report.comparisonDashboards.fullPath]
    >();

  const [compareObjs, setCompareObjs] = useState<
    ReportComparisonDashboardObject[]
  >([]);
  const [compareObjsForRequest, setCompareObjsForRequest] = useState<
    ReportComparisonDashboardObject[]
  >([]);

  const { dashboardId } =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.report.comparisonDashboards.fullPath>
    >();

  const { data, isFetching } = useGetApiReportComparisonDashboardsByIdQuery({
    id: dashboardId,
  });
  const navigateToBoard = (newBoardId: string) => {
    navigate(
      generateUrl(ROUTES_ROOT.report.comparisonDashboards.fullPath, {
        pathParams: {
          dashboardId,
        },
        queryParams: {
          boardId: newBoardId,
        },
      }),
      {
        replace: true,
      },
    );
  };

  const isObjCardSelected = (obj: ReportComparisonDashboardObject): boolean => {
    return Boolean(
      compareObjs?.find((o) => obj.id === o.id && obj._type === o._type),
    );
  };

  const onObjCardClick = (obj: ReportComparisonDashboardObject): void => {
    setCompareObjs((prev) =>
      isObjCardSelected(obj)
        ? prev.filter((o) => !(o._type === obj._type && o.id === obj.id))
        : [...prev, obj],
    );
  };

  const isCompareObjAndCompareObjsForRequestEqual = useMemo(
    () =>
      isEqual(sortBy(compareObjs, 'id'), sortBy(compareObjsForRequest, 'id')),
    [compareObjs, compareObjsForRequest],
  );

  useNavigateToInitialStateComparisonDashboardEffect({
    compareObjs,
    comparisonDashboardData: data,
    callbackWithFirstObjects: (firstObjects) => {
      setCompareObjs(firstObjects);
      setCompareObjsForRequest(firstObjects);
    },
  });

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId,
      boardId,
      dashboardType: ReportDashboardType.COMPARISON_MODE,
    }),
    [dashboardId, boardId],
  );

  const filteredAssets = useMemo(() => {
    const ordered = orderBy(data?.assets ?? [], [
      (asset) =>
        compareObjsForRequest.find(
          (o) => o._type === 'asset' && o.id === asset.id,
        )
          ? 0
          : 1,
      (asset) =>
        isItemFavorite({
          dashboardId,
          assetId: asset.id,
        })
          ? 0
          : 1,
      (asset) => asset.name,
    ]);

    return ordered.filter((asset) =>
      includesInLowerCase(asset.name, searchText),
    ) as IAsset[];
  }, [data, searchText, isItemFavorite, compareObjsForRequest]);

  const assetIds = useMemo(() => {
    return mapListToIds(compareObjs.filter(({ _type }) => _type === 'asset'));
  }, [compareObjs]);

  const assetIdsForRequest = useMemo(() => {
    return mapListToIds(
      compareObjsForRequest.filter(({ _type }) => _type === 'asset'),
    );
  }, [compareObjsForRequest]);

  const selectedBoard = data?.boards.find((b) => b.id === boardId);

  const legalEntityIdsForRequest = useMemo(() => {
    return (
      compareObjsForRequest
        .map(({ id }) => data?.assets.find((a) => a.id === id))
        .filter(Boolean)
        .flatMap((a) => a.legalEntities.map((l) => l.id)) ?? []
    );
  }, [data, compareObjsForRequest]);

  const isEnoughCompareObjs = useMemo(() => {
    return compareObjs.length > 1;
  }, [compareObjs]);

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <ComparisonDashboardLayout>
        <ComparisonDashboardLayout.SideBar>
          <ExplicitSuspense
            isLoading={isFetching}
            fallback={<ComparisonDashboardLayout.SideBarLoading />}
          >
            {data && (
              <DashboardLayout.Header className="py-tw-6">
                <DashboardLayout.Header.Title
                  withoutBackButton
                  classes={{
                    title: 'header4-bold',
                  }}
                  subtitle="Report"
                  title="Comparison"
                />
                <div className="grow" />
                <DashboardSettingsPageToggle
                  queryParams={{
                    assetId: assetIds[0],
                  }}
                />
              </DashboardLayout.Header>
            )}

            <div className="flex flex-col gap-tw-4 overflow-auto px-tw-6">
              <SearchInput
                size="s"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="flex items-center gap-tw-4">
                <div className="flex grow items-center gap-tw-2 rounded-[8px] bg-neutral-200 px-tw-3 py-tw-2">
                  <Icon iconName="magic" />
                  <p className="secondary-semibold text-neutral-550">
                    {!isEnoughCompareObjs
                      ? 'Select at least 2 Assets to compare'
                      : `${assetIds.length} Assets selected`}
                  </p>
                  <div className="grow" />
                </div>
                {isEnoughCompareObjs && (
                  <Button
                    onClick={() => {
                      setCompareObjsForRequest(compareObjs);
                    }}
                    disabled={isCompareObjAndCompareObjsForRequestEqual}
                    variant="success"
                    size="s"
                  >
                    Compare
                  </Button>
                )}
              </div>
              <OverlayScrollbarsComponent
                options={{
                  className: 'os-theme-thin-dark os-host-flexbox',
                }}
              >
                <ProjectCardList>
                  {filteredAssets.map((asset) => {
                    const obj = {
                      ...asset,
                      _type: 'asset',
                    } satisfies ReportComparisonDashboardObject;

                    return (
                      <ProjectCard
                        key={asset.id}
                        classes={{
                          image: 'hidden',
                        }}
                        className="cursor-pointer"
                        onClick={() => onObjCardClick(obj)}
                        pictureUrl={undefined}
                        selected={isObjCardSelected(obj)}
                        header={
                          <div className="flex h-full items-center gap-tw-2">
                            <p className="inline-semibold text-neutral-800">
                              {asset.name}
                            </p>
                            <div className="grow" />
                            <LegalEntitiesIconWithTooltip
                              legalEntities={asset.legalEntities ?? []}
                            />
                            <FavoriteIconButton
                              size="m"
                              selected={isItemFavorite({
                                dashboardId,
                                assetId: asset.id,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleItemFavorite({
                                  dashboardId,
                                  assetId: asset.id,
                                });
                              }}
                            />
                            <CheckboxWithRefreshButton
                              checked={isObjCardSelected(obj)}
                              onChange={() => onObjCardClick(obj)}
                            />
                          </div>
                        }
                      >
                        <ListLayout.NavigationCardList.Label
                          color={OBJECTABLE_TYPE_COLOR_MAP.asset}
                          text="Asset"
                        />
                      </ProjectCard>
                    );
                  })}
                </ProjectCardList>
              </OverlayScrollbarsComponent>
            </div>
          </ExplicitSuspense>
        </ComparisonDashboardLayout.SideBar>
        <ExplicitSuspense
          isLoading={isFetching}
          fallback={<AnimationLoader className="" />}
        >
          <ComparisonDashboardLayout.Body
            className={cn({
              'ml-[400px]': !fullscreen,
            })}
          >
            <div className="flex items-center gap-tw-4">
              <span className="header5-bold text-neutral-850">
                {data?.name}
              </span>
              <div className="grow" />
              <IconButton
                onClick={toggleFullscreen}
                iconName={fullscreen ? 'collapse' : 'expand'}
              />
            </div>
            <div className="h-1 w-full bg-neutral-300" />

            {data && (
              <DashboardBoards
                boards={data.boards}
                selectedBoardId={boardId}
                onBoardChange={navigateToBoard}
              >
                <ResponsiveGridLayout
                  {...DEFAULT_LAYOUT_PROPS}
                  className="w-full"
                  useCSSTransforms
                >
                  {selectedBoard?.sections.map((widgetSection) => (
                    <div
                      data-grid={widgetSection.position}
                      key={widgetSection.id}
                    >
                      <DashboardWidget
                        allAssets={data.assets}
                        assetIds={assetIdsForRequest}
                        legalEntityIds={legalEntityIdsForRequest}
                        comparisonDashboardId={dashboardId}
                        widgetSection={widgetSection}
                      />
                    </div>
                  ))}
                </ResponsiveGridLayout>
              </DashboardBoards>
            )}
          </ComparisonDashboardLayout.Body>
        </ExplicitSuspense>
      </ComparisonDashboardLayout>
    </DashboardContext.Provider>
  );
};
